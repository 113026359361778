import { handleActions } from 'redux-actions'
import {
  DISABLE_TENANT_RESPONSE,
  GET_TENANTS_RESPONSE,
  DELETE_TENANT_RESPONSE,
} from '../actions/types'

const tenants = handleActions(
  {
    [GET_TENANTS_RESPONSE]: {
      next: (state, action) => ({
        tenants: action.payload.data.items,
        meta: {
          ...state.meta,
          ...action.payload.data.meta,
          resultsPerPage: action.payload.meta.limit,
        },
      }),
    },
    [DISABLE_TENANT_RESPONSE]: {
      next: (state, action) => ({
        tenants: state.tenants.map(tenant => {
          if (tenant.id === action.payload.tenantId) {
            return { ...tenant, isEnabled: !action.payload.status }
          }
          return tenant
        }),
        meta: state.meta,
      }),
    },
    [DELETE_TENANT_RESPONSE]: {
      next: (state, action) => ({
        tenants: state.tenants.filter(
          tenant => tenant.id !== action.payload.tenantId,
        ),
        meta: state.meta,
      }),
    },
  },
  { tenants: [], meta: {} },
)

export default tenants
