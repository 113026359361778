import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { clearUiState } from '../../license/actions/index'

class NotFound extends Component {
  static propTypes = {
    clearUiState: PropTypes.func,
  }

  static defaultProps = {
    clearUiState: () => {},
  }

  componentWillUnmount() {
    const { clearUiState } = this.props
    clearUiState()
  }

  render() {
    return <h1>Not Found</h1>
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearUiState,
    },
    dispatch,
  )

export default withRouter(connect(() => ({}), mapDispatchToProps)(NotFound))
