import React from 'react'
import PropTypes from 'prop-types'

const LabelWithError = ({
  label,
  name,
  isError, // Whether the label should show an error
  errorLabel, // If label should be other than label when an error is shown
  error, // The error to be shown
  required,
}) => {
  let modifiedLabel = required ? `${label} *` : label

  let classNames = 'control-label'

  if (isError && error) {
    classNames = 'control-label error-label'
    modifiedLabel = errorLabel || label
  }

  return (
    <label htmlFor={name} className={classNames}>
      <strong>{modifiedLabel}</strong>
      {isError ? ` : ${error}` : null}
    </label>
  )
}

LabelWithError.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  isError: PropTypes.bool,
  errorLabel: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
}

LabelWithError.defaultProps = {
  name: '',
  isError: false,
  errorLabel: undefined,
  error: undefined,
  required: false,
}

export default LabelWithError
