import { handleActions } from 'redux-actions'
import {
  DELETE_OUTLET_RESPONSE,
  DISABLE_OUTLET_RESPONSE,
  GET_OUTLETS_PER_TENANT_RESPONSE,
} from '../actions/types'

const disableOutlet = (outlets, outletId, status = true) =>
  outlets.map(outlet =>
    outlet.id === outletId ? { ...outlet, isEnabled: !status } : outlet,
  )

const removeOutlet = (outlets, outletId) =>
  outlets.filter(outlet => outlet.outletId !== outletId)

const outlets = handleActions(
  {
    [GET_OUTLETS_PER_TENANT_RESPONSE]: {
      next: (state, action) => ({
        ...state,
        [action.payload.tenantId]: {
          outlets: action.payload.items,
          meta: action.payload.meta,
        },
      }),
    },
    [DISABLE_OUTLET_RESPONSE]: {
      next: (state, action) => ({
        ...state,
        [action.payload.tenantId]: {
          outlets: disableOutlet(
            state[action.payload.tenantId].outlets,
            action.payload.outletId,
            action.payload.status,
          ),
          meta: state[action.payload.tenantId].meta,
        },
      }),
    },
    [DELETE_OUTLET_RESPONSE]: {
      next: (state, action) => ({
        ...state,
        [action.payload.tenantId]: {
          outlets: removeOutlet(
            state[action.payload.tenantId].outlets,
            action.payload.id,
          ),
          meta: state[action.payload.tenantId].meta,
        },
      }),
    },
  },
  {},
)

export default outlets
export const outletsPerTenant = state => state.outlets
