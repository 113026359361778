import { handleActions } from 'redux-actions'
import {
  ADD_VOLUME_LICENSES_REQUEST,
  ADD_VOLUME_LICENSES_RESPONSE,
} from '../actions/types'

const addVolumeLicensesUi = handleActions(
  {
    [ADD_VOLUME_LICENSES_REQUEST]: () => ({
      isLoading: true,
    }),
    [ADD_VOLUME_LICENSES_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
  },
  {},
)

const getIsLoading = state => state.ui.addVolumeLicenses.isLoading
const getIsSuccessful = state => state.ui.addVolumeLicenses.isSuccessful
const getIsError = state => state.ui.addVolumeLicenses.isError
const getError = state => state.ui.addVolumeLicenses.error

export {
  addVolumeLicensesUi,
  getIsSuccessful,
  getIsLoading,
  getIsError,
  getError,
}
