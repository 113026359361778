import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RadioButtonsField from 'Components/RadioButtons/RadioButtonsField'

class FormikRadioButtonsField extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    label: PropTypes.string,
    field: PropTypes.shape({ name: PropTypes.string, value: PropTypes.string })
      .isRequired,
    form: PropTypes.shape({ setFieldValue: PropTypes.func }).isRequired,
    noLabel: PropTypes.bool,
  }

  static defaultProps = {
    label: '',
    onChange: () => {},
    noLabel: false,
  }

  handleOnSelect = event => {
    const { value } = event.target
    const {
      onChange,
      field: { name },
      form: { setFieldValue },
    } = this.props
    setFieldValue(name, value)
    onChange(event)
  }

  render = () => {
    const {
      field: { value },
    } = this.props

    return (
      <div className="form-group">
        <RadioButtonsField
          {...this.props}
          onSelect={this.handleOnSelect}
          selectedOption={value}
        />
      </div>
    )
  }
}

export default FormikRadioButtonsField
