import { handleActions } from 'redux-actions'
import {
  GET_SEARCH_LICENSES_RESPONSE,
  DISABLE_SEARCH_LICENSE_RESPONSE,
  DELETE_SEARCH_LICENSE_RESPONSE,
} from '../actions/types'

const searchLicense = handleActions(
  {
    [GET_SEARCH_LICENSES_RESPONSE]: {
      next: (state, action) => ({
        licenses: action.payload.data.items,
        meta: {
          ...state.meta,
          ...action.payload.data.meta,
          resultsPerPage: action.payload.meta.limit,
        },
      }),
    },
    [DISABLE_SEARCH_LICENSE_RESPONSE]: {
      next: (state, action) => ({
        licenses: state.licenses.map(license => {
          if (license.id === action.payload.licenseId) {
            return { ...license, isEnabled: !action.payload.status }
          }
          return license
        }),
        meta: state.meta,
      }),
    },
    [DELETE_SEARCH_LICENSE_RESPONSE]: {
      next: (state, action) => ({
        licenses: state.licenses.filter(
          license => license.id !== action.payload.licenseId,
        ),
        meta: state.meta,
      }),
    },
  },
  { licenses: [], meta: {} },
)

const getSearchLicenseList = state => state.search.licenses.licenses
const getSearchLicenseMeta = state => state.search.licenses.meta

export { getSearchLicenseList, getSearchLicenseMeta }
export default searchLicense
