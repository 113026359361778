export const DISABLE_TENANT_REQUEST = 'DISABLE_TENANT_REQUEST'
export const DISABLE_TENANT_RESPONSE = 'DISABLE_TENANT_RESPONSE'

export const DISABLE_OUTLET_REQUEST = 'DISABLE_OUTLET_REQUEST'
export const DISABLE_OUTLET_RESPONSE = 'DISABLE_OUTLET_RESPONSE'

export const GET_TENANTS_REQUEST = 'GET_TENANTS_REQUEST'
export const GET_TENANTS_RESPONSE = 'GET_TENANTS_RESPONSE'

export const ADD_TENANT_REQUEST = 'ADD_TENANT_REQUEST'
export const ADD_TENANT_RESPONSE = 'ADD_TENANT_RESPONSE'

export const EDIT_TENANT_REQUEST = 'EDIT_TENANT_REQUEST'
export const EDIT_TENANT_RESPONSE = 'EDIT_TENANT_RESPONSE'

export const GET_TENANT_DETAILS_REQUEST = 'GET_TENANT_DETAILS_REQUEST'
export const GET_TENANT_DETAILS_RESPONSE = 'GET_TENANT_DETAILS_RESPONSE'

export const DISABLE_SEARCH_TENANT_RESPONSE = 'DISABLE_SEARCH_TENANT_RESPONSE'
export const DISABLE_SEARCH_TENANT_REQUEST = 'DISABLE_SEARCH_TENANT_REQUEST'

export const DELETE_TENANT_REQUEST = 'DELETE_TENANT_REQUEST'
export const DELETE_TENANT_RESPONSE = 'DELETE_TENANT_RESPONSE'
export const DELETE_SEARCH_TENANT_REQUEST = 'DELETE_SEARCH_TENANT_REQUEST'
export const DELETE_SEARCH_TENANT_RESPONSE = 'DELETE_SEARCH_TENANT_RESPONSE'

export const SEARCH_TENANTS_REQUEST = 'SEARCH_TENANTS_REQUEST'
export const SEARCH_TENANTS_RESPONSE = 'SEARCH_TENANTS_RESPONSE'
export const SET_RESULTS_PER_TENANTS_PAGE = 'SET_RESULTS_PER_TENANTS_PAGE'

export const ENABLE_SEARCH_TENANT_REQUEST = 'ENABLE_SEARCH_TENANT_REQUEST'
export const ENABLE_SEARCH_TENANT_RESPONSE = 'ENABLE_SEARCH_TENANT_RESPONSE'

export const CLEAR_TENANT_DETAILS = 'CLEAR_TENANT_DETAILS'

export const DEFAULT_ORDER_BY = 'created'
export const DEFAULT_ORDER_DIRECTION = 'desc'
