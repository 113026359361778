import { handleActions } from 'redux-actions'
import {
  DISABLE_TENANT_ADMIN_USERS_RESPONSE,
  GET_TENANT_ADMIN_USERS_RESPONSE,
  ENABLE_TENANT_ADMIN_USERS_RESPONSE,
} from '../actions/types'

const tenantAdminUsersUi = handleActions(
  {
    [GET_TENANT_ADMIN_USERS_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
        isError: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
    [DISABLE_TENANT_ADMIN_USERS_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
        isError: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
    [ENABLE_TENANT_ADMIN_USERS_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
        isError: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
  },
  {},
)

export default tenantAdminUsersUi
