import { handleActions } from 'redux-actions'
import {
  ENABLE_LICENSE_RESPONSE,
  ENABLE_LICENSE_REQUEST,
} from '../actions/types'

const enableLicenseUi = handleActions(
  {
    [ENABLE_LICENSE_REQUEST]: () => ({
      isLoading: true,
      isSuccessful: false,
    }),
    [ENABLE_LICENSE_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
  },
  {},
)

const getIsEnableLoading = state => state.ui.license.enableLicense.isLoading
const getIsEnableSuccessful = state =>
  state.ui.license.enableLicense.isSuccessful
const getIsEnableError = state => state.ui.license.enableLicense.isError
const getEnableError = state => state.ui.license.enableLicense.error

export {
  getIsEnableSuccessful,
  getIsEnableLoading,
  getIsEnableError,
  getEnableError,
}
export default enableLicenseUi
