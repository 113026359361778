import { handleActions } from 'redux-actions'
import {
  DELETE_ANCON_ADMIN_SEARCH_USERS_RESPONSE,
  DELETE_PASSPORT_SEARCH_USERS_RESPONSE,
  DELETE_TENANT_ADMIN_SEARCH_USERS_RESPONSE,
  DELETE_ANCON_ADMIN_SEARCH_USERS_REQUEST,
  DELETE_PASSPORT_SEARCH_USERS_REQUEST,
  DELETE_TENANT_ADMIN_SEARCH_USERS_REQUEST,
} from '../actions/types'

const searchUsersDeleteUi = handleActions(
  {
    [DELETE_ANCON_ADMIN_SEARCH_USERS_REQUEST]: () => ({
      isLoading: true,
    }),
    [DELETE_ANCON_ADMIN_SEARCH_USERS_RESPONSE]: {
      next: () => ({
        isLoading: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
    [DELETE_PASSPORT_SEARCH_USERS_REQUEST]: () => ({
      isLoading: true,
    }),
    [DELETE_PASSPORT_SEARCH_USERS_RESPONSE]: {
      next: () => ({
        isLoading: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
    [DELETE_TENANT_ADMIN_SEARCH_USERS_REQUEST]: () => ({
      isLoading: true,
    }),
    [DELETE_TENANT_ADMIN_SEARCH_USERS_RESPONSE]: {
      next: () => ({
        isLoading: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
  },
  {},
)

export default searchUsersDeleteUi
export const getSearchUsersDeleteUi = state => state.ui.searchUsersDelete
