import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import firebase from 'firebase/app'
import 'firebase/auth'
import { setNewAccessToken } from 'Common/api'
import userManager from 'Common/userManager'
import intl from 'react-intl-universal'
import hydrateStore from 'Common/storeHydrator'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Loadable from 'react-loadable'
import Spinner from 'Components/Spinner/Spinner'
import RouteLoader from '../../../components/RouteLoader'
import { PrivateRoute } from '../../../components'
import AuthPage from '../../login/pages/authPage/AuthPage'
import { getContext } from '../../context/actions/context'

const MainPage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "main-page" */ '../pages/MainPage/MainPage'),
  loading: RouteLoader,
})

class RootRouter extends Component {
  static propTypes = {
    user: PropTypes.shape({}),
    history: PropTypes.shape({}),
  }

  static defaultProps = {
    user: undefined,
    history: undefined,
  }

  state = { initDone: false, isAuthenticated: false }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(this.handleAuthChanged)

    hydrateStore()
      .then(() => {})
      .catch(() => {
        this.setState({ initDone: false })
      })
  }

  componentDidUpdate = prevProps => {
    const { context } = this.props
    const { context: oldContext } = prevProps

    const newIdentityUserId =
      context.clientContext?.identityUser?.identityUserId
    const oldIdentityUserId =
      oldContext.clientContext?.identityUser?.identityUserId

    if (newIdentityUserId !== oldIdentityUserId) {
      if (newIdentityUserId && !context.clientContext?.isAdminUser) {
        setTimeout(this.signOut, 3000)
      }
      this.setState({ initDone: true }) // eslint-disable-line react/no-did-update-set-state
    }
  }

  Unauthorized = () => <>{intl.get('login.unauthorized')}</>

  signOut = async () => {
    const { history } = this.props
    await userManager.firebaseSignOut()
    history.push('/login')
  }

  handleAuthChanged = async user => {
    const { getContext } = this.props

    if (user) {
      const { claims } = await firebase.auth().currentUser.getIdTokenResult()

      if (!claims.superadmin) return

      const token = await firebase.auth().currentUser.getIdToken()

      await setNewAccessToken(token)
    }

    this.setState({ initDone: true, isAuthenticated: !!user })
    getContext()
  }

  render() {
    const { initDone, isAuthenticated } = this.state

    return (
      <Switch>
        {!initDone && <Route exact path="*" component={Spinner} />}
        {isAuthenticated && initDone && (
          <Route
            exact
            path="/login"
            render={() => <>{initDone && <Redirect to="/" />}</>}
          />
        )}
        {isAuthenticated && initDone && (
          <PrivateRoute
            path="/"
            component={props => (
              <MainPage {...props} onRequestSignOut={this.signOut} />
            )}
          />
        )}
        {!isAuthenticated && <Route path="/login" component={AuthPage} />}
        {!isAuthenticated && <Redirect to="/login" />}
        <Route
          path="/not-found"
          render={() => <h1>{intl.get('page.not_found')}</h1>}
        />
      </Switch>
    )
  }
}

const mapStateToProps = state => ({
  context: state.context,
})

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      getContext,
    },
    dispatch,
  ),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RootRouter),
)
