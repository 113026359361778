import { handleActions } from 'redux-actions'
import {
  EDIT_ANCON_ADMIN_USER_REQUEST,
  EDIT_ANCON_ADMIN_USER_RESPONSE,
  EDIT_ANCON_PASSPORT_USER_REQUEST,
  EDIT_TENANT_ADMIN_USER_REQUEST,
  EDIT_ANCON_PASSPORT_USER_RESPONSE,
  EDIT_TENANT_ADMIN_USER_RESPONSE,
} from '../actions/types'

const editUserUi = handleActions(
  {
    [EDIT_ANCON_ADMIN_USER_REQUEST]: () => ({
      isLoading: true,
    }),
    [EDIT_ANCON_PASSPORT_USER_REQUEST]: () => ({
      isLoading: true,
    }),
    [EDIT_TENANT_ADMIN_USER_REQUEST]: () => ({
      isLoading: true,
    }),
    [EDIT_ANCON_ADMIN_USER_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
    [EDIT_ANCON_PASSPORT_USER_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
    [EDIT_TENANT_ADMIN_USER_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
  },
  {},
)

const getIsLoading = state => state.ui.editUser.isLoading
const getIsSuccessful = state => state.ui.editUser.isSuccessful
const getIsError = state => state.ui.editUser.isError
const getError = state => state.ui.editUser.error

export { editUserUi, getIsSuccessful, getIsLoading, getIsError, getError }
