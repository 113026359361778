// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DarP14W3-BP5I6XCe7SPqQ\\=\\={display:flex;background:#f7f7fa;justify-content:space-between;align-items:center;margin-bottom:20px;border-radius:4px}.DarP14W3-BP5I6XCe7SPqQ\\=\\= p{margin:0px;padding-left:15px}", "",{"version":3,"sources":["webpack://src/components/ToggleLabel/ToggleLabel.module.scss"],"names":[],"mappings":"AAAA,4BAAO,YAAY,CAAC,kBAAkB,CAAC,6BAA6B,CAAC,kBAAkB,CAAC,kBAAkB,CAAC,iBAAiB,CAAC,8BAAS,UAAU,CAAC,iBAAiB","sourcesContent":[".label{display:flex;background:#f7f7fa;justify-content:space-between;align-items:center;margin-bottom:20px;border-radius:4px}.label p{margin:0px;padding-left:15px}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "DarP14W3-BP5I6XCe7SPqQ=="
};
export default ___CSS_LOADER_EXPORT___;
