import { handleActions } from 'redux-actions'
import {
  DISABLE_SEARCH_TENANT_RESPONSE,
  SEARCH_TENANTS_RESPONSE,
  SEARCH_TENANTS_REQUEST,
  DELETE_SEARCH_TENANT_RESPONSE,
  ENABLE_SEARCH_TENANT_RESPONSE,
} from '../actions/types'

import {
  DISABLE_SEARCH_OUTLET_RESPONSE,
  DELETE_SEARCH_OUTLET_RESPONSE,
} from '../../outlet/actions/types'

const disableOutlet = (outletArr, outletId, status = true) =>
  outletArr.map(outlet =>
    outlet.id === outletId ? { ...outlet, isEnabled: !status } : outlet,
  )

const removeOutlet = (outletArr, outletId) =>
  outletArr.filter(outlet => outlet.id !== outletId)

const searchTenants = handleActions(
  {
    [SEARCH_TENANTS_RESPONSE]: {
      next: (_, { payload }) => payload.data,
    },
    [DISABLE_SEARCH_TENANT_RESPONSE]: {
      next: (state, action) => ({
        items: state.items.map(tenant => {
          if (tenant.id === action.payload.tenantId) {
            return { ...tenant, isEnabled: !action.payload.status }
          }
          return tenant
        }),
        meta: state.meta,
      }),
    },
    [DELETE_SEARCH_TENANT_RESPONSE]: {
      next: (state, action) => ({
        items: state.items.filter(
          tenant => tenant.id !== action.payload.tenantId,
        ),
        meta: state.meta,
      }),
    },
    [ENABLE_SEARCH_TENANT_RESPONSE]: {
      next: (state, action) => ({
        items: state.items.map(tenant => {
          if (tenant.id === action.payload.tenantId) {
            return { ...tenant, isEnabled: !action.payload.status }
          }
          return tenant
        }),
        meta: state.meta,
      }),
    },
    [DISABLE_SEARCH_OUTLET_RESPONSE]: {
      next: (state, action) => ({
        items: state.items.map(tenant => {
          if (tenant.id === action.payload.tenantId) {
            return {
              ...tenant,
              outlets: disableOutlet(
                tenant.outlets,
                action.payload.outletId,
                action.payload.status,
              ),
            }
          }
          return tenant
        }),
        meta: state.meta,
      }),
    },
    [DELETE_SEARCH_OUTLET_RESPONSE]: {
      next: (state, action) => ({
        items: state.items.map(tenant => {
          if (tenant.id === action.payload.tenantId) {
            return {
              ...tenant,
              outlets: removeOutlet(tenant.outlets, action.payload.outletId),
            }
          }
          return tenant
        }),
        meta: state.meta,
      }),
    },
  },
  { tenants: [], meta: {} },
)

const searchTenantsUi = handleActions(
  {
    [SEARCH_TENANTS_REQUEST]: () => ({
      isLoading: true,
    }),
    [SEARCH_TENANTS_RESPONSE]: {
      next: () => ({
        isLoading: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
  },
  {},
)

const getIsLoading = state => state.ui.searchTenants.isLoading
const getIsError = state => state.ui.searchTenants.isError
const getError = state => state.ui.searchTenants.error

export { searchTenantsUi, getIsLoading, getIsError, getError }

export default searchTenants
