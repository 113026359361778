import React from 'react'
import PropTypes from 'prop-types'
import LabelWithError from '../LabelWithError'
import RadioButtons from './RadioButtons'

const RadioButtonsField = ({ noLabel, label, ...rest }) => (
  <>
    {noLabel ? null : <LabelWithError label={label} />}
    <RadioButtons {...rest} />
  </>
)

RadioButtonsField.propTypes = {
  label: PropTypes.string.isRequired,
  noLabel: PropTypes.bool,
}

RadioButtonsField.defaultProps = {
  noLabel: false,
}
export default RadioButtonsField
