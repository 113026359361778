import { handleActions } from 'redux-actions'
import {
  DISABLE_OUTLET_LICENSE_REQUEST,
  DISABLE_OUTLET_LICENSE_RESPONSE,
} from '../actions/types'

const deleteOutletLicenseUi = handleActions(
  {
    [DISABLE_OUTLET_LICENSE_REQUEST]: () => ({
      isLoading: true,
    }),
    [DISABLE_OUTLET_LICENSE_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
  },
  {},
)

const getIsSuccessful = state => state.ui.deleteOutletLicense.isSuccessful
const getIsLoading = state => state.ui.deleteOutletLicense.isLoading
const getIsError = state => state.ui.deleteOutletLicense.isError
const getError = state => state.ui.deleteOutletLicense.error

export {
  deleteOutletLicenseUi,
  getIsSuccessful,
  getIsLoading,
  getIsError,
  getError,
}
