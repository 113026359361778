import { handleActions } from 'redux-actions'
import { ADD_TENANT_REQUEST, ADD_TENANT_RESPONSE } from '../actions/types'

const addTenantUi = handleActions(
  {
    [ADD_TENANT_REQUEST]: () => ({
      isLoading: true,
    }),
    [ADD_TENANT_RESPONSE]: {
      next: (_, action) => ({
        id: action.payload.data.id,
        isLoading: false,
        isSuccessful: true,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
  },
  {},
)

const getIsLoading = state => state.ui.addTenant.isLoading
const getIsSuccessful = state => state.ui.addTenant.isSuccessful
const getIsError = state => state.ui.addTenant.isError
const getError = state => state.ui.addTenant.error
const getNewId = state => state.ui.addTenant.id

export {
  addTenantUi,
  getIsSuccessful,
  getIsLoading,
  getIsError,
  getError,
  getNewId,
}
