import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch, Route, withRouter } from 'react-router-dom'
import Loadable from 'react-loadable'
import intl from 'react-intl-universal'
import DocumentTitle from 'Components/DocumentTitle'
import { PrivateRoute } from '../../../components'
import { RouteTypes } from '../../../components/Navigation'
import { getNavigationUiStates } from '../../../store/reducers/uiState'
import RouterLoader from '../../../components/RouteLoader'
import NotFound from '../components/NotFound'

class MainContentRouter extends React.Component {
  static propTypes = { navigationUiStates: PropTypes.shape({}).isRequired }

  constructor(props) {
    super(props)
    this.state = { navigationUiStates: {} }
  }

  componentWillReceiveProps(nextProps) {
    const { navigationUiStates } = nextProps
    const navState = this.navigationUiStates(navigationUiStates)
    this.setState({
      navigationUiStates: navState,
    })
  }

  navigationUiStates = navigationUiStates => {
    const navUiStates = {}
    Object.keys(navigationUiStates).forEach(key => {
      if (navigationUiStates[key].isError) {
        if (navigationUiStates[key].error.status === 404) {
          Object.assign(navUiStates, { [key]: true })
        } else {
          Object.assign(navUiStates, { [key]: false })
        }
      } else {
        Object.assign(navUiStates, { [key]: false })
      }
    })
    return navUiStates
  }

  getIsNotFound = type => {
    const { navigationUiStates } = this.state
    return navigationUiStates[type]
  }

  render() {
    const renderBody = (
      <Switch>
        <PrivateRoute
          exact
          path="/"
          render={props => <Dashboard {...props} />}
        />
        <PrivateRoute
          path={`/${RouteTypes.tenant}`}
          render={props =>
            this.getIsNotFound('tenants.details') ? (
              <NotFound />
            ) : (
              <TenantRouter {...props} />
            )
          }
        />
        {/* <PrivateRoute
          path={`/${RouteTypes.licenses}`}
          render={props =>
            this.getIsNotFound('licenses.details') ? (
              <NotFound />
            ) : (
              <LicenseRouter {...props} />
            )
          }
        /> */}
        <PrivateRoute
          path={`/${RouteTypes.tenant}/:id/outlets`}
          render={props =>
            this.getIsNotFound('outlets.details') ? (
              <NotFound />
            ) : (
              <OutletRouter {...props} />
            )
          }
        />
        <PrivateRoute
          path={`/${RouteTypes.users}`}
          render={props =>
            this.getIsNotFound('users.details') ? (
              <NotFound />
            ) : (
              <UsersRouter {...props} />
            )
          }
        />
        <PrivateRoute
          path={`/${RouteTypes.broadcastMessages}`}
          component={BroadcastMessagesRouter}
        />
        <Route render={() => <h1>Page Not Found</h1>} />
      </Switch>
    )

    return renderBody
  }
}

const Dashboard = () => (
  <DocumentTitle title={intl.get('dashboard')}>
    <h1>{intl.get('dashboard')}</h1>
  </DocumentTitle>
)

const TenantRouter = Loadable({
  loader: () =>
    import(/* webpackChunkName: "tenant" */ '../../tenant/router/TenantRouter'),
  loading: RouterLoader,
})

const OutletRouter = Loadable({
  loader: () =>
    import(/* webpackChunkName: "outlet" */ '../../outlet/router/OutletRouter'),
  loading: RouterLoader,
})

const UsersRouter = Loadable({
  loader: () =>
    import(/* webpackChunkName: "users" */ '../../users/router/UsersRouter'),
  loading: RouterLoader,
})

const BroadcastMessagesRouter = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "broadcastMessages" */ '../../broadcastMessages/router/BroadcastMessagesRouter'
    ),
  loading: RouterLoader,
})

// const LicenseRouter = Loadable({
//   loader: () =>
//     import(/* webpackChunkName: "license" */ '../../license/router/LicenseRouter'),
//   loading: RouterLoader
// });

export default withRouter(
  connect(
    state => ({
      navigationUiStates: getNavigationUiStates(state),
    }),
    null,
  )(MainContentRouter),
)
