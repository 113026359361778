export const GET_OUTLET_DETAILS_REQUEST = 'GET_OUTLET_DETAILS_REQUEST'
export const GET_OUTLET_DETAILS_RESPONSE = 'GET_OUTLET_DETAILS_RESPONSE'

export const DELETE_OUTLET_REQUEST = 'DELETE_OUTLET_REQUEST'
export const DELETE_OUTLET_RESPONSE = 'DELETE_OUTLET_RESPONSE'

export const GET_OUTLETS_PER_TENANT_REQUEST = 'GET_OUTLETS_PER_TENANT_REQUEST'
export const GET_OUTLETS_PER_TENANT_RESPONSE = 'GET_OUTLETS_PER_TENANT_RESPONSE'

export const DELETE_SEARCH_OUTLET_REQUEST = 'DELETE_SEARCH_OUTLET_REQUEST'
export const DELETE_SEARCH_OUTLET_RESPONSE = 'DELETE_SEARCH_OUTLET_RESPONSE'

export const DISABLE_OUTLET_REQUEST = 'DISABLE_OUTLET_REQUEST'
export const DISABLE_OUTLET_RESPONSE = 'DISABLE_OUTLET_RESPONSE'

export const DISABLE_SEARCH_OUTLET_REQUEST = 'DISABLE_SEARCH_OUTLET_REQUEST'
export const DISABLE_SEARCH_OUTLET_RESPONSE = 'DISABLE_SEARCH_OUTLET_RESPONSE'

export const ADD_OUTLET_REQUEST = 'ADD_OUTLET_REQUEST'
export const ADD_OUTLET_RESPONSE = 'ADD_OUTLET_RESPONSE'

export const EDIT_OUTLET_REQUEST = 'EDIT_OUTLET_REQUEST'
export const EDIT_OUTLET_RESPONSE = 'EDIT_OUTLET_RESPONSE'

export const CLEAR_OUTLET_DETAILS = 'CLEAR_OUTLET_DETAILS'
export const CLEAR_UI = 'CLEAR_UI'

export const GET_OUTLET_ADMINS_RESPONSE = 'GET_OUTLET_ADMINS_RESPONSE'
export const GET_OUTLET_ADMINS_REQUEST = 'GET_OUTLET_ADMINS_REQUEST'
export const CLEAR_OUTLET_ADMINS = 'CLEAR_OUTLET_ADMINS'

export const ADD_VOLUME_LICENSES_REQUEST = 'ADD_LICENSES_REQUEST'
export const ADD_VOLUME_LICENSES_RESPONSE = 'ADD_LICENSES_RESPONSE'

export const GET_LICENSES_PER_OUTLET_REQUEST = 'GET_LICENSES_PER_OUTLET_REQUEST'
export const GET_LICENSES_PER_OUTLET_RESPONSE =
  'GET_LICENSES_PER_OUTLET_RESPONSE'

export const DISABLE_OUTLET_LICENSE_REQUEST = 'DISABLE_OUTLET_LICENSE_REQUEST'
export const DISABLE_OUTLET_LICENSE_RESPONSE = 'DISABLE_OUTLET_LICENSE_RESPONSE'

export const ENABLE_OUTLET_LICENSE_REQUEST = 'ENABLE_OUTLET_LICENSE_REQUEST'
export const ENABLE_OUTLET_LICENSE_RESPONSE = 'ENABLE_OUTLET_LICENSE_RESPONSE'
