const config = {
  api: {
    BASE_URL:
      (window.extConfig !== undefined && window.extConfig.REACT_APP_BASE_URL) ||
      process.env.REACT_APP_BASE_URL,
    MEDIA_URL:
      (window.extConfig !== undefined &&
        window.extConfig.REACT_APP_MEDIA_URL) ||
      process.env.REACT_APP_MEDIA_URL,
    GOOGLE_API_KEY:
      (window.extConfig !== undefined &&
        window.extConfig.REACT_APP_GOOGLE_API_KEY) ||
      process.env.REACT_APP_GOOGLE_API_KEY,
    CARD_API_URL:
      (window.extConfig !== undefined && window.extConfig.REACT_APP_CARD_API_URL) ||
      process.env.REACT_APP_CARD_API_URL,
    BUGSNAG_API_KEY:
      (window.extConfig !== undefined &&
        window.extConfig.REACT_APP_BUGSNAG_API_KEY) ||
      process.env.REACT_APP_BUGSNAG_API_KEY,
  },
  log: {
    CONSOLE_LOG_LEVEL:
      (window.extConfig !== undefined &&
        window.extConfig.REACT_APP_LOG_CONSOLE_LOG_LEVEL) ||
      process.env.REACT_APP_LOG_CONSOLE_LOG_LEVEL,
    REMOTE_LOG_LEVEL:
      (window.extConfig !== undefined &&
        window.extConfig.REACT_APP_LOG_REMOTE_LOG_LEVEL) ||
      process.env.REACT_APP_LOG_REMOTE_LOG_LEVEL,
    REMOTE_URL:
      (window.extConfig !== undefined &&
        window.extConfig.REACT_APP_LOG_REMOTE_URL) ||
      process.env.REACT_APP_LOG_REMOTE_URL,
    REMOTE_API_KEY:
      (window.extConfig !== undefined &&
        window.extConfig.REACT_APP_LOG_REMOTE_API_KEY) ||
      process.env.REACT_APP_LOG_REMOTE_API_KEY,
  },
}

export default {
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
  GOOGLE_MAPS_GEOCODE_BASE_URL: 'https://maps.googleapis.com/maps/api/geocode',
  GOOGLE_MAPS_TIMEZONE_BASE_URL:
    'https://maps.googleapis.com/maps/api/timezone',
  GOOGLE_MAPS_BASE_URL: 'https://maps.googleapis.com/maps/api/js?key=',
  DEFAULT_MAP_COORDINATES: { lat: 59.3293414, lng: 18.0592932 },
  ...config,
}
