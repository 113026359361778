import React from 'react'
import AuthContainer from '../../containers/AuthContainer'
import styles from './AuthPage.module.scss'

const LoginPage = props => (
  <div className={styles.background}>
    <AuthContainer {...props} />
  </div>
)

export default LoginPage
