import { handleActions } from 'redux-actions'
import {
  DELETE_SEARCH_TENANT_REQUEST,
  DELETE_SEARCH_TENANT_RESPONSE,
} from '../actions/types'
import {
  DELETE_SEARCH_OUTLET_REQUEST,
  DELETE_SEARCH_OUTLET_RESPONSE,
} from '../../outlet/actions/types'

const deleteSearchTenantUi = handleActions(
  {
    [DELETE_SEARCH_TENANT_REQUEST]: () => ({
      isLoading: true,
    }),
    [DELETE_SEARCH_OUTLET_REQUEST]: () => ({
      isLoading: true,
    }),
    [DELETE_SEARCH_TENANT_RESPONSE]: {
      next: () => ({
        isLoading: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
    [DELETE_SEARCH_OUTLET_RESPONSE]: {
      next: () => ({
        isLoading: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
  },
  {},
)

const getIsLoading = state => state.ui.deleteSearchTenant.isLoading
const getIsError = state => state.ui.deleteSearchTenant.isError
const getError = state => state.ui.deleteSearchTenant.error

const searchTenantOutletDeleteUi = state => ({
  isLoading: state.ui.deleteSearchTenant.isLoading,
  isError: state.ui.deleteSearchTenant.isError,
  error: state.ui.deleteSearchTenant.error,
})

export default deleteSearchTenantUi
export { getIsLoading, getIsError, getError, searchTenantOutletDeleteUi }
