import React from 'react'
import PropTypes from 'prop-types'

const LegendFieldset = ({ title, children, hasBorder = true }) => (
  <fieldset
    className={hasBorder ? 'section-fieldset' : 'section-fieldset-no-border'}
  >
    <legend>{title}</legend>
    {children}
  </fieldset>
)

LegendFieldset.propTypes = {
  title: PropTypes.string,
  hasBorder: PropTypes.bool,
  children: PropTypes.node,
}

LegendFieldset.defaultProps = {
  title: '',
  hasBorder: true,
  children: <div />,
}

export default LegendFieldset
