import React, { useEffect, useState } from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import intl from 'react-intl-universal'
import { useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import DocumentTitle from './components/DocumentTitle'
import './scss/ReactToastify.scss'
import { RootRouter } from './modules/main/router'
import log from './common/logger'

export const history = createBrowserHistory()

function App() {
  const locale = useSelector(state => state.locale)

  const [initDone, setInitDone] = useState(false)

  // Global uncaught error logging
  useEffect(() => {
    window.onerror = error => {
      log.error(error)
    }
  }, [])

  useEffect(() => {
    function loadLocales() {
      if (initDone) {
        setInitDone(false)
      }

      let currentLocale
      if (localStorage.getItem('locale') === null) {
        currentLocale = intl.determineLocale({
          urlLocaleKey: 'lang',
          cookieLocaleKey: 'lang',
        })
      } else {
        currentLocale = localStorage.getItem('locale')
      }

      if (locale != null) {
        currentLocale = locale
      }

      if (currentLocale.includes('-')) {
        currentLocale = currentLocale.substring(0, 2)
      }

      import(`./locales/${currentLocale}.json`)
        .then(data => {
          intl
            .init({ currentLocale, locales: { [currentLocale]: data } })
            .then(() => {
              localStorage.setItem('locale', currentLocale)
              if (!initDone) setInitDone(true)
            })
        })
        .catch(() =>
          import(`./locales/en.json`).then(data => {
            intl
              .init({ currentLocale, locales: { [currentLocale]: data } })
              .then(() => {
                localStorage.setItem('locale', currentLocale)
                if (!initDone) setInitDone(true)
              })
          }),
        )
    }

    loadLocales()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  return initDone ? (
    <DocumentTitle>
      <div className="App">
        <Router history={history}>
          <div>
            <RootRouter />
          </div>
        </Router>
        <ToastContainer hideProgressBar />
      </div>
    </DocumentTitle>
  ) : null
}

export default App
