import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import intl from 'react-intl-universal'
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap'

const UserConfirmationModal = ({
  isOpen,
  toggle,
  onConfirm,
  onCancel,
  message,
  modalBody,
  positiveActionTitle,
  negativeActionTitle,
  data,
}) => (
  <>
    <Modal isOpen={isOpen} toggle={toggle} fade={false}>
      <ModalHeader>{message}</ModalHeader>
      {modalBody ? <ModalBody>{modalBody}</ModalBody> : <></>}
      <ModalFooter>
        <Button color="secondary" onClick={() => onConfirm(data)}>
          {negativeActionTitle || intl.get('leave')}
        </Button>
        <Button color="primary" onClick={() => onCancel(data)}>
          {positiveActionTitle || intl.get('stay')}
        </Button>
      </ModalFooter>
    </Modal>
  </>
)

UserConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  message: PropTypes.string,
  positiveActionTitle: PropTypes.string,
  negativeActionTitle: PropTypes.string,
  modalBody: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape(),
    PropTypes.number,
  ]),
}

UserConfirmationModal.defaultProps = {
  isOpen: false,
  toggle: () => {},
  onConfirm: () => {},
  onCancel: () => {},
  message: '',
  modalBody: '',
  positiveActionTitle: undefined,
  negativeActionTitle: undefined,
  data: {},
}

export default UserConfirmationModal
