import { SET_LOCALE } from '../actions/types'

const defaultLocale = localStorage.getItem('locale')

const locale = (state = defaultLocale, action) => {
  switch (action.type) {
    case SET_LOCALE:
      return action.locale
    default:
      return state
  }
}

export default locale
