import React from 'react'
import styles from './AuthDialog.module.scss'
import logo from './anconTango-logo.png'
import userManager from '../../../common/userManager'

class AuthDialog extends React.Component {
  // static propTypes = {
  //   location: PropTypes.shape({ state: PropTypes.shape({}) }).isRequired
  // };

  constructor() {
    super()

    this.state = {
      currentDialog: 'login',
      loggedIn: '',
      currentError: '',
      isLoading: false,
    }

    this.signedIn = false
    this.userEmail = ''
    this.userPass = ''
    this.userRecoveryEmail = ''
  }

  // componentWillMount() {
  //   const { location } = this.props;
  //   const { from } = location.state || {
  //     from: { pathname: location.pathname }
  //   };
  //   let encodedFrom = encodeURI(from.pathname);
  //   if (encodedFrom.split('?')[0] === '/login') {
  //     encodedFrom = '/';
  //   }
  //   if (!userManager.isUserLoggedIn()) {
  //     // userManager.signIn(encodedFrom);
  //   }
  // }

  handleSignInWithEmail = async e => {
    e.preventDefault()
    try {
      this.setState({ isLoading: true })
      await userManager.firebaseSignIn(
        this.userEmail.value,
        this.userPass.value,
      )
    } catch (err) {
      this.setState({
        currentError: err.message,
        isLoading: false,
      })
      userManager.firebaseSignOut()
    }
  }

  handleRecoverEmail = () => {
    const { userRecoveryEmail } = this

    userManager.firebasePasswordReset(userRecoveryEmail.value)
  }

  handleForgotPasswordClick = () => {
    this.setState({ currentDialog: 'forgotpassword' })
  }

  handleRecoverHelpClick = () => {
    this.setState({ currentDialog: 'recovery' })
  }

  handleGoToLoginClick = () => {
    this.setState({ currentDialog: 'login' })
  }

  renderCurrentDialog = () => {
    const { currentDialog } = this.state

    const isAuthenticated = userManager.isAuthenticated()

    if (isAuthenticated === null) {
      if (currentDialog === 'login') {
        return this.renderLoginView()
      }
      if (currentDialog === 'forgotpassword') {
        return this.renderPasswordResetView()
      }
    } else {
      if (currentDialog === 'login') {
        return this.renderLoginView()
      }
      if (currentDialog === 'forgotpassword') {
        return this.renderPasswordResetView()
      }
    }
  }

  renderPasswordResetView = () => (
    <div className={styles.authDialog}>
      <div className={styles.logos}>
        <img src={logo} alt="Ancon Passport" className={styles.authLogo} />
        <div className={styles.motto}>
          <span className={styles.sysadmin}>sysadmin</span>
        </div>
      </div>

      <div className="recoveryFormWrapper">
        <h3 className={styles.resetHeader}>Forgot your password?</h3>
        <p className={styles.resetText}>
          Fear not. We will email you instructions to reset your password.
        </p>
        <form className={styles.recoveryForm}>
          <label htmlFor="recoveryEmail">EMAIL</label>
          <input
            name="recoveryEmail"
            placeholder="john@email.com"
            type="text"
            style={styles.inputName}
            id="inputRecoveryEmail"
            ref={recoveryInput => (this.userRecoveryEmail = recoveryInput)}
          />

          <button
            className={styles.sendInstructions}
            onClick={this.handleRecoverEmail}
          >
            Send instructions
          </button>
        </form>
      </div>

      <div className={styles.recoveryButtons}>
        <button className={styles.toLogin} onClick={this.handleGoToLoginClick}>
          Go to login
        </button>
        <button
          className={styles.additionalRecoveryHelp}
          onClick={this.handleRecoveryClick}
        >
          I need to recover my account
        </button>
      </div>
    </div>
  )

  renderLoginView = () => {
    const { currentError, loggedIn, isLoading } = this.state

    return (
      <div className={styles.authDialog}>
        <div className={styles.logos}>
          <img src={logo} alt="Ancon Passport" className={styles.authLogo} />
          <div className={styles.motto}>
            <span className={styles.sysadmin}>sysadmin</span>
          </div>
        </div>
        {/* implement later */}
        {/* <div className={styles.socials}>
          <div className={styles.google}>Continue in with Google</div>
          <div className={styles.facebook}>Continue in with Facebook</div>
        </div>

        <div className={styles.separator}>
          <hr />
          <span>OR</span>
          <hr />
        </div> */}
        <form
          className={styles.signInForm}
          onSubmit={this.handleSignInWithEmail}
        >
          <label htmlFor="email">EMAIL</label>
          <input
            name="email"
            placeholder="john@email.com"
            type="text"
            style={styles.inputName}
            id="inputEmail"
            ref={userEmailInput => (this.userEmail = userEmailInput)}
          />
          <br />
          <label htmlFor="password">PASSWORD</label>
          <input
            name="password"
            placeholder="********"
            type="password"
            style={styles.inputSecret}
            id="inputSecret"
            ref={userSecretInput => (this.userPass = userSecretInput)}
          />
          {currentError !== '' && (
            <div className={styles.errorMessages}>
              <span>{currentError}</span>
            </div>
          )}
          {loggedIn !== '' && (
            <div className={styles.successMessage}>
              <span>You were successfully signed in! Redirecting...</span>
            </div>
          )}

          <div className={styles.signInButtons}>
            <button
              type="submit"
              className={styles.continue}
              disabled={isLoading}
            >
              {isLoading ? 'Signing in...' : 'Continue with e-mail'}
            </button>
            <button
              type="button"
              className={styles.recover}
              onClick={this.handleForgotPasswordClick}
            >
              Forgot password?
            </button>
          </div>
        </form>
      </div>
    )
  }

  renderAuthDialog = () => {
    const currentView = this.renderCurrentDialog()

    return (
      <div className={styles.container}>
        <div className={styles.languagePicker}>
          <div className={styles.dropdown}>
            <div className={styles.dropdownTitle}>English (UK)</div>
            <div className={styles.dropdownSub}>
              <div className={styles.dropdownOption}>Svenska</div>
              <div className={styles.dropdownOption}>Afrikaans</div>
              <div className={styles.dropdownOption}>Deutsch</div>
            </div>
          </div>
          <i
            className="a_icon-arrow_drop_down"
            style={{ 'font-size': '24px' }}
          />
        </div>
        {currentView}
        <div className={styles.footer}>
          <div>
            <span className={styles.copyright}>&copy; 2022 </span>- Ancon AB.
          </div>
          <div>
            <a href="/">Privacy</a>
            <a href="/">Terms</a>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return this.renderCurrentDialog()
  }
}

export default AuthDialog
