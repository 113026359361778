const GET_PASSPORT_USERS_RESPONSE = 'GET_PASSPORT_USERS_RESPONSE'
const GET_PASSPORT_USERS_REQUEST = 'GET_PASSPORT_USERS_REQUEST'

const GET_CUSTOMERS_REQUEST = 'GET_CUSTOMERS_REQUEST'
const GET_CUSTOMERS_RESPONSE = 'GET_CUSTOMERS_RESPONSE'
const SEARCH_CUSTOMERS_REQUEST = 'SEARCH_CUSTOMERS_REQUEST'
const SEARCH_CUSTOMERS_RESPONSE = 'SEARCH_CUSTOMERS_RESPONSE'
const DISABLE_CUSTOMERS_RESPONSE = 'DISABLE_CUSTOMERS_RESPONSE'
const ENABLE_CUSTOMERS_RESPONSE = 'ENABLE_CUSTOMERS_RESPONSE'
const DELETE_CUSTOMERS_RESPONSE = 'DELETE_CUSTOMERS_RESPONSE'

const GET_IDENTITY_USER_BY_ID_REQUEST = 'GET_IDENTITY_USER_BY_ID_REQUEST'
const GET_IDENTITY_USER_BY_ID_RESPONSE = 'GET_IDENTITY_USER_BY_ID_RESPONSE'

// disable users
const DISABLE_ANCON_ADMIN_USERS_REQUEST = 'DISABLE_ANCON_ADMIN_USERS_REQUEST'
const DISABLE_ANCON_ADMIN_USERS_RESPONSE = 'DISABLE_ANCON_ADMIN_USERS_RESPONSE'
const DISABLE_TENANT_ADMIN_USERS_REQUEST = 'DISABLE_TENANT_ADMIN_USERS_REQUEST'
const DISABLE_TENANT_ADMIN_USERS_RESPONSE =
  'DISABLE_TENANT_ADMIN_USERS_RESPONSE'
const DISABLE_PASSPORT_USERS_REQUEST = 'DISABLE_PASSPORT_USERS_REQUEST'
const DISABLE_PASSPORT_USERS_RESPONSE = 'DISABLE_PASSPORT_USERS_RESPONSE'
// disable users

const GET_ANCON_ADMIN_USERS_RESPONSE = 'GET_ANCON_ADMIN_USERS_RESPONSE'
const GET_ANCON_ADMIN_USERS_REQUEST = 'GET_ANCON_ADMIN_USERS_REQUEST'
const GET_TENANT_ADMIN_USERS_RESPONSE = 'GET_TENANT_ADMIN_USERS_RESPONSE'
const GET_TENANT_ADMIN_USERS_REQUEST = 'GET_TENANT_ADMIN_USERS_REQUEST'

const GET_ANCON_ADMIN_BY_ID_REQUEST = 'GET_ANCON_ADMIN_BY_ID_REQUEST'
const GET_ANCON_ADMIN_BY_ID_RESPONSE = 'GET_ANCON_ADMIN_BY_ID_RESPONSE'
const GET_PASSPORT_HOLDER_BY_ID_REQUEST = 'GET_PASSPORT_HOLDER_BY_ID_REQUEST'
const GET_PASSPORT_HOLDER_BY_ID_RESPONSE = 'GET_PASSPORT_HOLDER_BY_ID_RESPONSE'
const GET_TENANT_ADMIN_BY_ID_REQUEST = 'GET_TENANT_ADMIN_BY_ID_REQUEST'
const GET_TENANT_ADMIN_BY_ID_RESPONSE = 'GET_TENANT_ADMIN_BY_ID_RESPONSE'

const SEARCH_ANCON_ADMIN_USERS_REQUEST = 'SEARCH_ANCON_ADMIN_USERS_REQUEST'
const SEARCH_ANCON_ADMIN_USERS_RESPONSE = 'SEARCH_ANCON_ADMIN_USERS_RESPONSE'
const SEARCH_PASSPORT_USERS_REQUEST = 'SEARCH_PASSPORT_USERS_REQUEST'
const SEARCH_PASSPORT_USERS_RESPONSE = 'SEARCH_PASSPORT_USERS_RESPONSE'
const SEARCH_TENANT_ADMIN_USERS_REQUEST = 'SEARCH_TENANT_ADMIN_USERS_REQUEST'
const SEARCH_TENANT_ADMIN_USERS_RESPONSE = 'SEARCH_TENANT_ADMIN_USERS_RESPONSE'

const GET_USER_CONNECTIONS_TENANTS_REQUEST =
  'GET_USER_CONNECTIONS_TENANTS_REQUEST'
const GET_USER_CONNECTIONS_TENANTS_RESPONSE =
  'GET_USER_CONNECTIONS_TENANTS_RESPONSE'

const GET_USER_CONNECTIONS_OUTLETS_REQUEST =
  'GET_USER_CONNECTIONS_OUTLETS_REQUEST'
const GET_USER_CONNECTIONS_OUTLETS_RESPONSE =
  'GET_USER_CONNECTIONS_OUTLETS_RESPONSE'

const DELETE_TENANT_CONNECTION_RESPONSE = 'DELETE_TENANT_CONNECTION_RESPONSE'
const DELETE_OUTLET_CONNECTION_RESPONSE = 'DELETE_OUTLET_CONNECTION'
const DELETE_TENANT_CONNECTION_REQUEST = 'DELETE_TENANT_CONNECTION_REQUEST'
const DELETE_OUTLET_CONNECTION_REQUEST = 'DELETE_OUTLET_CONNECTION_REQUEST'

const DELETE_ANCON_ADMIN_USERS_RESPONSE = 'DELETE_ANCON_ADMIN_USERS_RESPONSE'
const DELETE_PASSPORT_USERS_RESPONSE = 'DELETE_PASSPORT_USERS_RESPONSE'
const DELETE_ANCON_ADMIN_USERS_REQUEST = 'DELETE_ANCON_ADMIN_USERS_REQUEST'
const DELETE_PASSPORT_USERS_REQUEST = 'DELETE_PASSPORT_USERS_REQUEST'
const DELETE_TENANT_ADMIN_USERS_RESPONSE = 'DELETE_TENANT_ADMIN_USERS_RESPONSE'
const DELETE_TENANT_ADMIN_USERS_REQUEST = 'DELETE_TENANT_ADMIN_USERS_REQUEST'

const ENABLE_ANCON_ADMIN_USERS_REQUEST = 'ENABLE_ANCON_ADMIN_USERS_REQUEST'
const ENABLE_ANCON_ADMIN_USERS_RESPONSE = 'ENABLE_ANCON_ADMIN_USERS_RESPONSE'
const ENABLE_PASSPORT_USERS_REQUEST = 'ENABLE_PASSPORT_USERS_REQUEST'
const ENABLE_PASSPORT_USERS_RESPONSE = 'ENABLE_PASSPORT_USERS_RESPONSE'
const ENABLE_TENANT_ADMIN_USERS_REQUEST = 'ENABLE_TENANT_ADMIN_USERS_REQUEST'
const ENABLE_TENANT_ADMIN_USERS_RESPONSE = 'ENABLE_TENANT_ADMIN_USERS_RESPONSE'

const DELETE_ANCON_ADMIN_SEARCH_USERS_REQUEST =
  'DELETE_ANCON_ADMIN_SEARCH_USERS_REQUEST'
const DELETE_ANCON_ADMIN_SEARCH_USERS_RESPONSE =
  'DELETE_ANCON_ADMIN_SEARCH_USERS_RESPONSE'
const DELETE_PASSPORT_SEARCH_USERS_REQUEST =
  'DELETE_PASSPORT_SEARCH_USERS_REQUEST'
const DELETE_PASSPORT_SEARCH_USERS_RESPONSE =
  'DELETE_PASSPORT_SEARCH_USERS_RESPONSE'
const DELETE_TENANT_ADMIN_SEARCH_USERS_REQUEST =
  'DELETE_TENANT_ADMIN_SEARCH_USERS_REQUEST'
const DELETE_TENANT_ADMIN_SEARCH_USERS_RESPONSE =
  'DELETE_TENANT_ADMIN_SEARCH_USERS_RESPONSE'
const ENABLE_ANCON_ADMIN_SEARCH_USERS_REQUEST =
  'ENABLE_ANCON_ADMIN_SEARCH_USERS_REQUEST'
const ENABLE_ANCON_ADMIN_SEARCH_USERS_RESPONSE =
  'ENABLE_ANCON_ADMIN_SEARCH_USERS_RESPONSE'
const ENABLE_PASSPORT_SEARCH_USERS_REQUEST =
  'ENABLE_PASSPORT_SEARCH_USERS_REQUEST'
const ENABLE_PASSPORT_SEARCH_USERS_RESPONSE =
  'ENABLE_PASSPORT_SEARCH_USERS_RESPONSE'
const ENABLE_TENANT_ADMIN_SEARCH_USERS_REQUEST =
  'ENABLE_TENANT_ADMIN_SEARCH_USERS_REQUEST'
const ENABLE_TENANT_ADMIN_SEARCH_USERS_RESPONSE =
  'ENABLE_TENANT_ADMIN_SEARCH_USERS_RESPONSE'

export {
  DISABLE_PASSPORT_USERS_RESPONSE,
  DISABLE_ANCON_ADMIN_USERS_REQUEST,
  DISABLE_PASSPORT_USERS_REQUEST,
  DISABLE_TENANT_ADMIN_USERS_REQUEST,
  DISABLE_TENANT_ADMIN_USERS_RESPONSE,
  DISABLE_ANCON_ADMIN_USERS_RESPONSE,
  GET_ANCON_ADMIN_USERS_RESPONSE,
  GET_ANCON_ADMIN_USERS_REQUEST,
  GET_PASSPORT_USERS_REQUEST,
  GET_PASSPORT_USERS_RESPONSE,
  GET_TENANT_ADMIN_USERS_RESPONSE,
  GET_TENANT_ADMIN_USERS_REQUEST,
  GET_ANCON_ADMIN_BY_ID_REQUEST,
  GET_ANCON_ADMIN_BY_ID_RESPONSE,
  GET_PASSPORT_HOLDER_BY_ID_REQUEST,
  GET_PASSPORT_HOLDER_BY_ID_RESPONSE,
  GET_TENANT_ADMIN_BY_ID_REQUEST,
  GET_TENANT_ADMIN_BY_ID_RESPONSE,
  GET_USER_CONNECTIONS_TENANTS_REQUEST,
  GET_USER_CONNECTIONS_TENANTS_RESPONSE,
  GET_USER_CONNECTIONS_OUTLETS_REQUEST,
  GET_USER_CONNECTIONS_OUTLETS_RESPONSE,
  DELETE_TENANT_CONNECTION_RESPONSE,
  DELETE_OUTLET_CONNECTION_RESPONSE,
  DELETE_TENANT_CONNECTION_REQUEST,
  DELETE_OUTLET_CONNECTION_REQUEST,
  SEARCH_ANCON_ADMIN_USERS_RESPONSE,
  SEARCH_PASSPORT_USERS_REQUEST,
  SEARCH_PASSPORT_USERS_RESPONSE,
  SEARCH_TENANT_ADMIN_USERS_REQUEST,
  SEARCH_TENANT_ADMIN_USERS_RESPONSE,
  SEARCH_ANCON_ADMIN_USERS_REQUEST,
  DELETE_ANCON_ADMIN_USERS_RESPONSE,
  DELETE_PASSPORT_USERS_RESPONSE,
  DELETE_ANCON_ADMIN_USERS_REQUEST,
  DELETE_PASSPORT_USERS_REQUEST,
  DELETE_TENANT_ADMIN_USERS_RESPONSE,
  DELETE_TENANT_ADMIN_USERS_REQUEST,
  ENABLE_ANCON_ADMIN_USERS_REQUEST,
  ENABLE_ANCON_ADMIN_USERS_RESPONSE,
  ENABLE_PASSPORT_USERS_REQUEST,
  ENABLE_PASSPORT_USERS_RESPONSE,
  ENABLE_TENANT_ADMIN_USERS_REQUEST,
  ENABLE_TENANT_ADMIN_USERS_RESPONSE,
  DELETE_ANCON_ADMIN_SEARCH_USERS_REQUEST,
  DELETE_ANCON_ADMIN_SEARCH_USERS_RESPONSE,
  DELETE_PASSPORT_SEARCH_USERS_REQUEST,
  DELETE_PASSPORT_SEARCH_USERS_RESPONSE,
  DELETE_TENANT_ADMIN_SEARCH_USERS_REQUEST,
  DELETE_TENANT_ADMIN_SEARCH_USERS_RESPONSE,
  ENABLE_ANCON_ADMIN_SEARCH_USERS_REQUEST,
  ENABLE_ANCON_ADMIN_SEARCH_USERS_RESPONSE,
  ENABLE_PASSPORT_SEARCH_USERS_REQUEST,
  ENABLE_PASSPORT_SEARCH_USERS_RESPONSE,
  ENABLE_TENANT_ADMIN_SEARCH_USERS_REQUEST,
  ENABLE_TENANT_ADMIN_SEARCH_USERS_RESPONSE,
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMERS_RESPONSE,
  SEARCH_CUSTOMERS_REQUEST,
  SEARCH_CUSTOMERS_RESPONSE,
  DISABLE_CUSTOMERS_RESPONSE,
  ENABLE_CUSTOMERS_RESPONSE,
  DELETE_CUSTOMERS_RESPONSE,
  GET_IDENTITY_USER_BY_ID_REQUEST,
  GET_IDENTITY_USER_BY_ID_RESPONSE,
}

export const ADD_ANCON_ADMIN_USER_REQUEST = 'ADD_ANCON_ADMIN_USER_REQUEST'
export const ADD_ANCON_ADMIN_USER_RESPONSE = 'ADD_ANCON_ADMIN_USER_RESPONSE'

export const CLEAR_ANCON_ADMIN_DETAILS = 'CLEAR_ANCON_ADMIN_DETAILS'
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER'
export const CLEAR_CURRENT_USER_UI = 'CLEAR_CURRENT_USER_UI'
export const CLEAR_UI = 'CLEAR_UI'

export const EDIT_ANCON_ADMIN_USER_REQUEST = 'EDIT_ANCON_ADMIN_USER_REQUEST'
export const EDIT_ANCON_ADMIN_USER_RESPONSE = 'EDIT_ANCON_ADMIN_USER_RESPONSE'

export const EDIT_TENANT_ADMIN_USER_REQUEST = 'EDIT_TENANT_ADMIN_USER_REQUEST'
export const EDIT_TENANT_ADMIN_USER_RESPONSE = 'EDIT_TENANT_ADMIN_USER_RESPONSE'

export const EDIT_ANCON_PASSPORT_USER_REQUEST =
  'EDIT_ANCON_PASSPORT_USER_REQUEST'
export const EDIT_ANCON_PASSPORT_USER_RESPONSE =
  'EDIT_ANCON_PASSPORT_USER_RESPONSE'

export const EDIT_IDENTITY_USER_REQUEST = "EDIT_IDENTITY_USER_REQUEST"
export const EDIT_IDENTITY_USER_RESPONSE = "EDIT_IDENTITY_USER_RESPONSE"

export const DELETE_IDENTITY_USER_REQUEST = "DELETE_IDENTITY_USER_REQUEST"
export const DELETE_IDENTITY_USER_RESPONSE = "DELETE_IDENTITY_USER_RESPONSE"
  
export const GET_ADMIN_USER_DETAILS_REQUEST = 'GET_ADMIN_USER_DETAILS_REQUEST'
export const GET_ADMIN_USER_DETAILS_RESPONSE = 'GET_ADMIN_USER_DETAILS_RESPONSE'

export const ADD_CONNECTION_REQUEST = 'ADD_CONNECTION_REQUEST'
export const ADD_CONNECTION_RESPONSE = 'ADD_CONNECTION_RESPONSE'
