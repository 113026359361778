import { handleActions } from 'redux-actions'
import { EDIT_LICENSE_REQUEST, EDIT_LICENSE_RESPONSE } from '../actions/types'

const editLicenseUi = handleActions(
  {
    [EDIT_LICENSE_REQUEST]: () => ({
      isLoading: true,
    }),
    [EDIT_LICENSE_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload,
      }),
    },
  },
  {},
)

const getIsLoading = state => state.ui.editLicense.isLoading
const getIsSuccessful = state => state.ui.editLicense.isSuccessful
const getIsError = state => state.ui.editLicense.isError
const getError = state => state.ui.editLicense.error

export { editLicenseUi, getIsSuccessful, getIsLoading, getIsError, getError }
