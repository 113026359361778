import { handleActions } from 'redux-actions'
import {
  DISABLE_OUTLET_REQUEST,
  DISABLE_OUTLET_RESPONSE,
} from '../actions/types'

const disableOutletUi = handleActions(
  {
    [DISABLE_OUTLET_REQUEST]: () => ({
      isLoading: true,
    }),
    [DISABLE_OUTLET_RESPONSE]: {
      next: () => ({
        isLoading: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
  },
  {},
)

const getIsLoading = state => state.ui.disableOutlet.isLoading
const getIsError = state => state.ui.disableOutlet.isError
const getError = state => state.ui.disableOutlet.error

export { disableOutletUi, getIsLoading, getIsError, getError }
