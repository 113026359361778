import { handleActions } from 'redux-actions'
import { ADD_LICENSE_RESPONSE, ADD_LICENSE_REQUEST } from '../actions/types'

const addLicenseUi = handleActions(
  {
    [ADD_LICENSE_REQUEST]: () => ({
      isLoading: true,
    }),
    [ADD_LICENSE_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload,
      }),
    },
  },
  {},
)

const getIsLoading = state => state.ui.addLicense.isLoading
const getIsSuccessful = state => state.ui.addLicense.isSuccessful
const getIsError = state => state.ui.addLicense.isError
const getError = state => state.ui.addLicense.error

export { addLicenseUi, getIsSuccessful, getIsLoading, getIsError, getError }
