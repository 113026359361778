import { combineReducers } from 'redux'
import {
  currentTenantUi,
  searchTenantsUi,
  addTenantUi,
  editTenantUi,
  disableTenantUi,
  getTenantsUi,
  deleteTenantUi,
  deleteSearchTenantUi,
} from '../../modules/tenant/reducers'
import {
  disableOutletUi,
  getOutletsUi,
  currentOutletUi,
  addOutletUi,
  editOutletUi,
  currentOutletAdminsUi,
  addVolumeLicensesUi,
  outletLicensesUi,
  deleteOutletUi,
  deleteOutletLicenseUi,
  enableOutletLicenseUi,
} from '../../modules/outlet/reducers'
import {
  getUsersUi,
  addAnconAdminUi,
  addConnectionUi,
  tenantAdminUsersUi,
  passportUsersUi,
  customersUi,
  anconAdminUsersUi,
  passportUsersDeleteUi,
  tenantAdminUsersDeleteUi,
  anconAdminUsersDeleteUi,
  searchUsersDeleteUi,
  editIdentityUserUi,
  deleteIdentityUserUi,
} from '../../modules/users/reducers'
import { currentUserUi } from '../../modules/users/reducers/currentUser'
import { loaderUi } from './loaderUi'
import { disableOutletConnectionUi } from '../../modules/users/reducers/disableOutletConnectionUi'
import { disableTenantConnectionUi } from '../../modules/users/reducers/disableTenantConnectionUi'
import {
  searchUsersUi,
  searchUsersActionsUi,
} from '../../modules/users/reducers/searchUsers'
import { currentLicenseUi } from '../../modules/license/reducers/currentLicense'
import licenseUi, {
  addLicenseUi,
  editLicenseUi,
  disableLicenseUi,
  deleteLicenseUi,
  deleteSearchLicenseUi,
} from '../../modules/license/reducers'
import {
  broadcastMessagesUi,
  deleteBroadcastMessageUi,
  addBroadcastMessageUi,
  currentBroadcastMessageUi,
  publishBroadcastMessageUi,
} from '../../modules/broadcastMessages/reducers'

const combinedUi = combineReducers({
  currentTenant: currentTenantUi,
  searchTenants: searchTenantsUi,
  addTenant: addTenantUi,
  editTenant: editTenantUi,
  disableTenant: disableTenantUi,
  deleteTenant: deleteTenantUi,
  getTenants: getTenantsUi,
  deleteSearchTenant: deleteSearchTenantUi,
  disableOutlet: disableOutletUi,
  getOutlets: getOutletsUi,
  currentOutlet: currentOutletUi,
  addOutlet: addOutletUi,
  editOutlet: editOutletUi,
  deleteOutlet: deleteOutletUi,
  currentOutletAdmins: currentOutletAdminsUi,
  addAnconAdmin: addAnconAdminUi,
  editUser: editIdentityUserUi,
  deleteIdentityUser: deleteIdentityUserUi,
  getUsers: getUsersUi,
  tenantAdminUsers: tenantAdminUsersUi,
  passportUsers: passportUsersUi,
  customers: customersUi,
  anconAdminUsers: anconAdminUsersUi,
  currentUser: currentUserUi,
  addConnection: addConnectionUi,
  disableOutletConnection: disableOutletConnectionUi,
  disableTenantConnection: disableTenantConnectionUi,
  searchUsers: searchUsersUi,
  searchUsersActions: searchUsersActionsUi,
  searchUsersDelete: searchUsersDeleteUi,
  passportUsersDelete: passportUsersDeleteUi,
  tenantAdminUsersDelete: tenantAdminUsersDeleteUi,
  anconAdminUsersDelete: anconAdminUsersDeleteUi,
  currentLicense: currentLicenseUi,
  addLicense: addLicenseUi,
  editLicense: editLicenseUi,
  license: licenseUi,
  addVolumeLicenses: addVolumeLicensesUi,
  outletLicenses: outletLicensesUi,
  deleteLicense: deleteLicenseUi,
  disableLicense: disableLicenseUi,
  deleteOutletLicense: deleteOutletLicenseUi,
  deleteSearchLicense: deleteSearchLicenseUi,
  enableOutletLicense: enableOutletLicenseUi,
  broadcastMessages: broadcastMessagesUi,
  deleteBroadcastMessage: deleteBroadcastMessageUi,
  addBroadcastMessage: addBroadcastMessageUi,
  currentBroadcastMessage: currentBroadcastMessageUi,
  publishBroadcastMessage: publishBroadcastMessageUi,
  loaderUi: () => false,
})

export const getNavigationUiStates = state => ({
  'tenants.details': state.ui.currentTenant,
  'outlets.details': state.ui.currentOutlet,
  'users.details': state.ui.currentUser,
  'licenses.details': state.ui.currentLicense,
})

const ui = (state = { ui: {} }, action) => ({
  ...combinedUi(state.ui, action),
  loaderUi: loaderUi(state, action),
})

export default ui
