import { handleActions } from 'redux-actions'
import {
  CLEAR_CURRENT_BROADCAST_MESSAGE,
  GET_CURRENT_BROADCAST_MESSAGE_RESPONSE,
} from '../actions/types'

const currentBroadcastMessage = handleActions(
  {
    [GET_CURRENT_BROADCAST_MESSAGE_RESPONSE]: {
      next: (state, action) => action.payload.broadcastMessage,
    },
    [CLEAR_CURRENT_BROADCAST_MESSAGE]: {
      next: () => ({}),
    },
  },
  {},
)

export default currentBroadcastMessage
