import { handleActions } from 'redux-actions'
import { GET_BROADCAST_MESSAGES_RESPONSE } from '../actions/types'

const broadcastMessages = handleActions(
  {
    [GET_BROADCAST_MESSAGES_RESPONSE]: {
      next: (state, action) => action.payload,
    },
  },
  {
    items: [],
    meta: {},
  },
)

export default broadcastMessages
