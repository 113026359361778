import { handleActions } from 'redux-actions'
import {
  DELETE_OUTLET_CONNECTION_REQUEST,
  DELETE_OUTLET_CONNECTION_RESPONSE,
} from '../actions/types'

const disableTenantConnectionUi = handleActions(
  {
    [DELETE_OUTLET_CONNECTION_REQUEST]: () => ({
      isLoading: true,
    }),
    [DELETE_OUTLET_CONNECTION_RESPONSE]: {
      next: () => ({
        isLoading: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
  },
  {},
)

const getIsLoading = state => state.ui.disableTenantConnection.isLoading
const getIsError = state => state.ui.disableTenantConnection.isError
const getError = state => state.ui.disableTenantConnection.error

export { disableTenantConnectionUi, getIsLoading, getIsError, getError }
