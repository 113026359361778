import React from 'react'
import PropTypes from 'prop-types'
import { Field, getIn } from 'formik'

const FormikField = ({
  field, // { name, value, onChange, onBlur } // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  errorLabel,
  form,
  required,
  label,
  readOnly,
  ...props
}) => {
  const error = getIn(form.errors, field.name)
  const touch = getIn(form.touched, field.name)
  return (
    <div className="form-group">
      <ErrorMessage
        name={field.name}
        label={required ? `${label} *` : label}
        errorLbl={errorLabel}
      />
      <div>
        <input
          {...field}
          {...props}
          className={error && touch ? 'form-control has-error' : 'form-control'}
          readOnly={readOnly}
          onBlur={event => {
            handleBlur(event, field.onChange)
            form.handleBlur(event)
          }}
        />
      </div>
    </div>
  )
}

const handleBlur = (event, onChange) => {
  event.target.value = event.target.value.trim()
  onChange(event)
}

const ErrorMessage = ({ name, label, errorLbl = '' }) => (
  <Field
    name={name}
    render={({ form }) => {
      const error = getIn(form.errors, name)
      const touch = getIn(form.touched, name)
      const errorLabel = errorLbl === '' ? label : errorLbl

      const errorMessage = `${errorLabel
        .replace('*', '')
        .toUpperCase()}: ${error}`
      return touch && error ? (
        <label htmlFor={name} className={`${'control-label'} ${'error-label'}`}>
          {errorMessage}
        </label>
      ) : (
        <label htmlFor={name} className="control-label">
          {label}
        </label>
      )
    }}
  />
)

ErrorMessage.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  errorLbl: PropTypes.string,
}
ErrorMessage.defaultProps = {
  errorLbl: '',
}

FormikField.propTypes = {
  field: PropTypes.shape({}),
  errorLabel: PropTypes.string,
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  form: PropTypes.shape({}),
}

FormikField.defaultProps = {
  field: {},
  label: '',
  errorLabel: '',
  readOnly: false,
  required: false,
  form: {},
}

export default FormikField
