import React from 'react'
import PropTypes from 'prop-types'
import { NavLink as ReactRouterNavLink } from 'react-router-dom'
import { slugify } from '../../common/textUtils'
import RouteTypes from './RouteTypes'
import Actions from './NavActions'

const NavLink = props => {
  const { type, id, name, disabled, children, extendUrl } = props
  const { extendUrl: extendUrlTemp, ...rest } = props
  let { action } = props
  action = action === undefined ? '' : `/${action}`

  const url = `${extendUrl}/${type}/${id}/${slugify(name)}${action}`
  return disabled ? (
    <>{children}</>
  ) : (
    <ReactRouterNavLink to={{ pathname: url, state: { name } }} {...rest} />
  )
}

NavLink.propTypes = {
  action: PropTypes.oneOf([Actions.edit, Actions.create]),
  type: PropTypes.oneOf(Object.values(RouteTypes)).isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  extendUrl: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  disabled: PropTypes.bool,
}

NavLink.defaultProps = {
  id: '',
  extendUrl: '',
  action: undefined,
  disabled: false,
}

export default NavLink
