import {
  ADD_TENANT_REQUEST,
  ADD_TENANT_RESPONSE,
  EDIT_TENANT_REQUEST,
  EDIT_TENANT_RESPONSE,
  GET_TENANTS_REQUEST,
  GET_TENANTS_RESPONSE,
  GET_TENANT_DETAILS_RESPONSE,
  GET_TENANT_DETAILS_REQUEST,
  SEARCH_TENANTS_REQUEST,
  SEARCH_TENANTS_RESPONSE,
} from '../../modules/tenant/actions/types'

import {
  ADD_OUTLET_REQUEST,
  EDIT_OUTLET_REQUEST,
  ADD_OUTLET_RESPONSE,
  EDIT_OUTLET_RESPONSE,
  GET_OUTLET_DETAILS_REQUEST,
  GET_OUTLET_DETAILS_RESPONSE,
} from '../../modules/outlet/actions/types'

import {
  GET_ANCON_ADMIN_USERS_RESPONSE,
  GET_ANCON_ADMIN_USERS_REQUEST,
  GET_PASSPORT_USERS_REQUEST,
  GET_PASSPORT_USERS_RESPONSE,
  GET_TENANT_ADMIN_USERS_RESPONSE,
  GET_TENANT_ADMIN_USERS_REQUEST,
  SEARCH_ANCON_ADMIN_USERS_REQUEST,
  SEARCH_ANCON_ADMIN_USERS_RESPONSE,
  SEARCH_PASSPORT_USERS_REQUEST,
  SEARCH_PASSPORT_USERS_RESPONSE,
  SEARCH_TENANT_ADMIN_USERS_REQUEST,
  SEARCH_TENANT_ADMIN_USERS_RESPONSE,
  GET_ANCON_ADMIN_BY_ID_REQUEST,
  GET_ANCON_ADMIN_BY_ID_RESPONSE,
  GET_PASSPORT_HOLDER_BY_ID_REQUEST,
  GET_PASSPORT_HOLDER_BY_ID_RESPONSE,
  GET_TENANT_ADMIN_BY_ID_REQUEST,
  GET_CUSTOMERS_REQUEST,
  GET_TENANT_ADMIN_BY_ID_RESPONSE,
  GET_IDENTITY_USER_BY_ID_RESPONSE,
  GET_IDENTITY_USER_BY_ID_REQUEST,
  EDIT_IDENTITY_USER_REQUEST,
  EDIT_IDENTITY_USER_RESPONSE,
  DELETE_IDENTITY_USER_REQUEST,
  DELETE_IDENTITY_USER_RESPONSE,
} from '../../modules/users/actions/types'

import {
  GET_LICENSES_RESPONSE,
  GET_LICENSES_REQUEST,
  ADD_LICENSE_REQUEST,
  GET_LICENSE_DETAILS_REQUEST,
  GET_LICENSE_DETAILS_RESPONSE,
  EDIT_LICENSE_REQUEST,
  ADD_LICENSE_RESPONSE,
  EDIT_LICENSE_RESPONSE,
} from '../../modules/license/actions/types'

const loaderUi = (state, action) => {
  switch (action.type) {
    case ADD_TENANT_REQUEST:
    case EDIT_TENANT_REQUEST:
    case EDIT_IDENTITY_USER_REQUEST:
    case DELETE_IDENTITY_USER_REQUEST:
    case GET_OUTLET_DETAILS_REQUEST:
    case ADD_OUTLET_REQUEST:
    case EDIT_OUTLET_REQUEST:
    case GET_ANCON_ADMIN_BY_ID_REQUEST:
    case GET_PASSPORT_HOLDER_BY_ID_REQUEST:
    case GET_TENANT_ADMIN_BY_ID_REQUEST:
    case GET_TENANT_DETAILS_REQUEST:
    case SEARCH_TENANTS_REQUEST:
    case ADD_LICENSE_REQUEST:
    case EDIT_LICENSE_REQUEST:
    case GET_LICENSE_DETAILS_REQUEST:
    case GET_IDENTITY_USER_BY_ID_REQUEST:
      return true
    case GET_ANCON_ADMIN_USERS_REQUEST:
      return !anconAdminUsersAvailable(state)
    case GET_PASSPORT_USERS_REQUEST:
      return !passportUsersAvailable(state)
    case GET_CUSTOMERS_REQUEST:
      return !customersAvailable(state)
    case GET_TENANT_ADMIN_USERS_REQUEST:
      return !tenantAdminsAvailable(state)
    case GET_TENANTS_REQUEST:
      return !tenantsAvailable(state)
    case GET_LICENSES_REQUEST:
      return !licensesAvailable(state)
    case SEARCH_ANCON_ADMIN_USERS_REQUEST:
    case SEARCH_PASSPORT_USERS_REQUEST:
    case SEARCH_TENANT_ADMIN_USERS_REQUEST:
      return !searchTenantsAvailable(state)

    case ADD_TENANT_RESPONSE:
    case EDIT_TENANT_RESPONSE:
    case EDIT_IDENTITY_USER_RESPONSE:
    case DELETE_IDENTITY_USER_RESPONSE:    
    case ADD_OUTLET_RESPONSE:
    case GET_OUTLET_DETAILS_RESPONSE:
    case EDIT_OUTLET_RESPONSE:
    case GET_TENANTS_RESPONSE:
    case GET_ANCON_ADMIN_USERS_RESPONSE:
    case GET_PASSPORT_USERS_RESPONSE:
    case GET_TENANT_ADMIN_USERS_RESPONSE:
    case SEARCH_ANCON_ADMIN_USERS_RESPONSE:
    case SEARCH_PASSPORT_USERS_RESPONSE:
    case SEARCH_TENANT_ADMIN_USERS_RESPONSE:
    case GET_ANCON_ADMIN_BY_ID_RESPONSE:
    case GET_PASSPORT_HOLDER_BY_ID_RESPONSE:
    case GET_TENANT_ADMIN_BY_ID_RESPONSE:
    case GET_TENANT_DETAILS_RESPONSE:
    case SEARCH_TENANTS_RESPONSE:
    case ADD_LICENSE_RESPONSE:
    case GET_LICENSES_RESPONSE:
    case EDIT_LICENSE_RESPONSE:
    case GET_LICENSE_DETAILS_RESPONSE:
    case GET_IDENTITY_USER_BY_ID_RESPONSE:
      return false
    default:
      return state.ui !== undefined && state.ui.loaderUi !== undefined
        ? state.ui.loaderUi
        : false
  }
}

const tenantsAvailable = state =>
  // TODO: Check for pagination
  state.tenants.tenants.length > 0

const licensesAvailable = state =>
  // TODO: Check for pagination
  state.licenses.licenses.length > 0

const getIsLoading = state => state.ui.loaderUi

const anconAdminUsersAvailable = state =>
  // TODO: Check for pagination
  state.anconAdminUsers.items.length > 0
const passportUsersAvailable = state =>
  // TODO: Check for pagination

  state.passportUsers.items.length > 0

const tenantAdminsAvailable = state =>
  // TODO: Check for pagination
  state.tenantAdminUsers.items.length > 0

const customersAvailable = (/* state */) =>
  // TODO: Check for pagination
  // state.customers.items.length > 0;
  true

const searchTenantsAvailable = state =>
  // TODO: Check for pagination
  state.search.users.users.length > 0

export { loaderUi, getIsLoading }
