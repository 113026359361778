import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import './polyfills'

import './scss/bootstrap.scss'
import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import store from './store'
import bugsnagClient from './features/bugsnag'

const container = document.getElementById('root')
const root = createRoot(container)

const ErrorBoundary = bugsnagClient
  .getPlugin('react')
  .createErrorBoundary(React)

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
