import { handleActions } from 'redux-actions'
import { DELETE_TENANT_REQUEST, DELETE_TENANT_RESPONSE } from '../actions/types'

const deleteTenantUi = handleActions(
  {
    [DELETE_TENANT_REQUEST]: () => ({
      isLoading: true,
    }),
    [DELETE_TENANT_RESPONSE]: {
      next: () => ({
        isLoading: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
  },
  {},
)

const getIsSuccessful = state => state.ui.deleteTenant.isSuccessful
const getIsLoading = state => state.ui.deleteTenant.isLoading
const getIsError = state => state.ui.deleteTenant.isError
const getError = state => state.ui.deleteTenant.error

const tenantDeleteUi = state => ({
  isSuccessful: state.ui.deleteTenant.isSuccessful,
  isLoading: state.ui.deleteTenant.isLoading,
  isError: state.ui.deleteTenant.isError,
  error: state.ui.deleteTenant.error,
})

export {
  deleteTenantUi,
  tenantDeleteUi,
  getIsSuccessful,
  getIsLoading,
  getIsError,
  getError,
}
