import { createAction } from 'redux-actions'
import {
  GET_USER_DETAILS_REQUEST,
  GET_USER_DETAILS_RESPONSE,
  GET_IDENTITY_USER_RESPONSE,
} from './types'
import userManager from '../../../common/userManager'

const getUserDetailsResponse = createAction(GET_USER_DETAILS_RESPONSE)
const getUserDetailsRequest = createAction(GET_USER_DETAILS_REQUEST)
const getIdentityUserResponse = createAction(GET_IDENTITY_USER_RESPONSE)

export function getUserDetails() {
  return dispatch => {
    dispatch(getUserDetailsRequest())
    userManager
      .getUser()
      .then(response => {
        dispatch(getUserDetailsResponse(response))
      })
      .catch(err => {
        dispatch(getUserDetailsResponse(err))
      })
  }
}

export default {}
export { getIdentityUserResponse }
