import React from 'react'
import { Input } from 'reactstrap'
import PropTypes from 'prop-types'
import style from './RadioButtons.module.scss'

const RadioButton = ({ name, value, label, checked, onSelect }) => (
  <label className={style.defaultRadioContainer}>
    <Input
      type="radio"
      name={name === undefined ? label : name}
      value={value}
      checked={checked}
      onChange={onSelect}
    />
    <span className={style.checkMark} />
    <p>{label}</p>
  </label>
)

RadioButton.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
}

RadioButton.defaultProps = {
  name: undefined,
}

export default RadioButton
