export const GET_LICENSES_REQUEST = 'GET_LICENSES_REQUEST'
export const GET_LICENSES_RESPONSE = 'GET_LICENSES_RESPONSE'

export const GET_SEARCH_LICENSES_RESPONSE = 'GET_SEARCH_LICENSES_RESPONSE'
export const GET_SEARCH_LICENSES_REQUEST = 'GET_SEARCH_LICENSES_REQUEST'

export const GET_LICENSE_DETAILS_REQUEST = 'GET_LICENSE_DETAILS_REQUEST'
export const GET_LICENSE_DETAILS_RESPONSE = 'GET_LICENSE_DETAILS_RESPONSE'

export const ADD_LICENSE_REQUEST = 'ADD_LICENSE_REQUEST'
export const ADD_LICENSE_RESPONSE = 'ADD_LICENSE_RESPONSE'

export const EDIT_LICENSE_REQUEST = 'EDIT_LICENSE_REQUEST'
export const EDIT_LICENSE_RESPONSE = 'EDIT_LICENSE_RESPONSE'

export const CLEAR_LICENSE_DETAILS = 'CLEAR_LICENSE_DETAILS'
export const CLEAR_UI = 'CLEAR_UI'

export const DISABLE_LICENSE_REQUEST = 'DISABLE_LICENSE_REQUEST'
export const DISABLE_LICENSE_RESPONSE = 'DISABLE_LICENSE_RESPONSE'

export const DELETE_LICENSE_REQUEST = 'DELETE_LICENSE_REQUEST'
export const DELETE_LICENSE_RESPONSE = 'DELETE_LICENSE_RESPONSE'

export const ENABLE_LICENSE_REQUEST = 'ENABLE_LICENSE_REQUEST'
export const ENABLE_LICENSE_RESPONSE = 'ENABLE_LICENSE_RESPONSE'

export const ENABLE_SEARCH_LICENSE_REQUEST = 'ENABLE_SEARCH_LICENSE_REQUEST'
export const ENABLE_SEARCH_LICENSE_RESPONSE = 'ENABLE_SEARCH_LICENSE_RESPONSE'

export const DISABLE_SEARCH_LICENSE_REQUEST = 'DISABLE_SEARCH_LICENSE_REQUEST'
export const DISABLE_SEARCH_LICENSE_RESPONSE = 'DISABLE_SEARCH_LICENSE_RESPONSE'

export const DELETE_SEARCH_LICENSE_REQUEST = 'DELETE_SEARCH_LICENSE_REQUEST'
export const DELETE_SEARCH_LICENSE_RESPONSE = 'DELETE_SEARCH_LICENSE_RESPONSE'
