import React, { Component } from 'react'
import intl from 'react-intl-universal'
import PropTypes from 'prop-types'
import { Button, ButtonGroup } from 'reactstrap'

class ToggleButton extends Component {
  constructor(props) {
    super(props)

    this.state = {
      toggleState: /* props.disabled ? 2 :  */ props.initialState,
    }

    this.DEFAULT_OPTIONS = [intl.get('no'), intl.get('yes')]
  }

  static getDerivedStateFromProps = (props /* , state */) =>
    // if (props.disabled) {
    //   return {
    //     toggleState: /*  props.disabled ? 2 : */ state.toggleState
    //   };
    // } else {
    ({
      toggleState: props.initialState,
    })
  // }

  handleToggle = value => {
    const { onToggle } = this.props
    this.setState({ toggleState: value })
    onToggle(value)
  }

  render = () => {
    const { disabled, options } = this.props
    const { toggleState } = this.state

    const [firstOption, secondOption] = options ?? this.DEFAULT_OPTIONS

    return (
      <ButtonGroup
        // disabled={disabled}
        className={`toggle-btn-group ${toggleState === 1 ? 'selected-1' : ''} ${
          toggleState === 2 ? 'selected-2' : ''
        }`}
      >
        <Button
          tabIndex={-1}
          disabled={disabled}
          color={toggleState === 1 ? 'primary' : 'secondary'}
          outline={toggleState !== 1}
          onClick={() => this.handleToggle(1)}
          active={toggleState === 1}
        >
          {firstOption}
        </Button>
        <Button
          disabled={disabled}
          tabIndex={-1}
          color={toggleState === 2 ? 'primary' : 'secondary'}
          outline={toggleState !== 2}
          onClick={() => this.handleToggle(2)}
          active={toggleState === 2}
        >
          {secondOption}
        </Button>
      </ButtonGroup>
    )
  }
}

ToggleButton.propTypes = {
  initialState: PropTypes.number,
  onToggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string),
}

ToggleButton.defaultProps = {
  initialState: 1,
  disabled: false,
  options: undefined,
}

export default ToggleButton
