import { combineReducers } from 'redux'
import searchTenants from '../../modules/tenant/reducers/searchTenants'
import searchUsers from '../../modules/users/reducers/searchUsers'
import { searchLicense } from '../../modules/license/reducers'

const search = combineReducers({
  tenants: searchTenants,
  users: searchUsers,
  licenses: searchLicense,
})

export default search
