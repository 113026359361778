import { handleActions } from 'redux-actions'
import {
  DELETE_TENANT_ADMIN_USERS_RESPONSE,
  DELETE_TENANT_ADMIN_USERS_REQUEST,
} from '../actions/types'

const tenantAdminUsersDeleteUi = handleActions(
  {
    [DELETE_TENANT_ADMIN_USERS_REQUEST]: () => ({
      isLoading: true,
    }),
    [DELETE_TENANT_ADMIN_USERS_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
        isError: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
  },
  {},
)

export default tenantAdminUsersDeleteUi
