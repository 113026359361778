import { handleActions } from 'redux-actions'
import {
  CLEAR_OUTLET_DETAILS,
  GET_OUTLET_DETAILS_REQUEST,
  GET_OUTLET_DETAILS_RESPONSE,
} from '../actions/types'

const currentOutlet = handleActions(
  {
    [GET_OUTLET_DETAILS_RESPONSE]: {
      next: (_, { payload }) => payload,
    },
    [CLEAR_OUTLET_DETAILS]: () => ({}),
  },
  {},
)

const currentOutletUi = handleActions(
  {
    [GET_OUTLET_DETAILS_REQUEST]: () => ({
      isLoading: true,
    }),
    [GET_OUTLET_DETAILS_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isError: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.response,
      }),
    },
    [CLEAR_OUTLET_DETAILS]: {
      next: state => {
        if (state.error) {
          if (state.error.status === 404) {
            return state
          }
          return {}
        }
        return {}
      },
    },
  },
  {},
)

const getIsLoading = state => state.ui.currentTenant.isLoading
const getIsError = state => state.ui.currentTenant.isError
const getError = state => state.ui.currentTenant.error

export { currentOutletUi, getIsLoading, getIsError, getError }
export default currentOutlet
