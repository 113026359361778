import axios from 'axios'
import config from '../config'
import userManager from './userManager'

export const setNewAccessToken = token => {
  setAccessToken(token)
}

// Get IETF tag
function getLocaleTag(locale) {
  switch (locale) {
    case 'sv':
      return 'sv-SE'

    default:
      return 'en-US'
  }
}

const axiosApi = axios.create({
  baseURL: config.api.BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Client: 'sysadmin',
    'Client-Version': process.env.REACT_APP_VERSION,
  },
})

const mediaApi = axios.create({
  baseURL: config.api.MEDIA_URL,
})

const cardApi = axios.create({
  baseURL: config.api.CARD_API_URL,
})

axiosApi.interceptors.response.use(
  response => response,
  function (error) {
    if (error.response.status === 401) {
      userManager.firebaseSignOut()
      // eslint-disable-next-line no-console
      console.log('You were unauthorized for this action, signing you out.')
    } else {
      return Promise.reject(error)
    }
  },
)

const setAccessToken = token => {
  if (token !== undefined && token !== null) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  }
}

export function setAcceptLanguage(locale) {
  if (locale !== undefined && locale !== null) {
    axiosApi.defaults.headers.common['Accept-Language'] = getLocaleTag(locale)
  }
}

setAccessToken(localStorage.getItem('token'))
setAcceptLanguage(localStorage.getItem('locale'))

export { mediaApi, cardApi }
export default axiosApi
