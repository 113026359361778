import { combineReducers } from 'redux'
import disableLicenseUi from './disableLicenseUi'
import deleteLicenseUi from './deleteLicenseUi'
import enableLicenseUi from './enableLicenseUi'
import deleteSearchLicenseUi from './deleteSearchLicenseUi'

const licenseUi = combineReducers({
  disableLicense: disableLicenseUi,
  deleteLicense: deleteLicenseUi,
  enableLicense: enableLicenseUi,
  deleteSearchLicense: deleteSearchLicenseUi,
})

export default licenseUi
