import { handleActions } from 'redux-actions'
import {
  GET_OUTLETS_PER_TENANT_REQUEST,
  GET_OUTLETS_PER_TENANT_RESPONSE,
} from '../actions/types'

const getOutletsUi = handleActions(
  {
    [GET_OUTLETS_PER_TENANT_REQUEST]: () => ({
      isLoading: true,
    }),
    [GET_OUTLETS_PER_TENANT_RESPONSE]: {
      next: () => ({
        isLoading: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
  },
  {},
)

const getIsSuccessful = state => state.ui.getOutlets.isSuccessful
const getIsLoading = state => state.ui.getOutlets.isLoading
const getIsError = state => state.ui.getOutlets.isError
const getError = state => state.ui.getOutlets.error

const outletsUi = state => ({
  isSuccessful: state.ui.getOutlets.isSuccessful,
  getIsLoading: state.ui.getOutlets.isLoading,
  getIsError: state.ui.getOutlets.isError,
  getError: state.ui.getOutlets.error,
})

export {
  getOutletsUi,
  outletsUi,
  getIsSuccessful,
  getIsLoading,
  getIsError,
  getError,
}
