import { handleActions } from 'redux-actions'
import {
  DISABLE_TENANT_ADMIN_USERS_RESPONSE,
  GET_TENANT_ADMIN_USERS_RESPONSE,
  ENABLE_TENANT_ADMIN_USERS_RESPONSE,
  DELETE_TENANT_ADMIN_USERS_RESPONSE,
} from '../actions/types'

const tenantAdminUsers = handleActions(
  {
    [GET_TENANT_ADMIN_USERS_RESPONSE]: {
      next: (state, action) => action.payload,
    },
    [DISABLE_TENANT_ADMIN_USERS_RESPONSE]: {
      next: (state, action) => ({
        users: state.users.map(user => {
          if (user.platformUserId === action.payload.userId) {
            return { ...user, isEnabled: false }
          }
          return user
        }),
        meta: state.meta,
      }),
    },
    [ENABLE_TENANT_ADMIN_USERS_RESPONSE]: {
      next: (state, action) => ({
        users: state.users.map(user => {
          if (user.platformUserId === action.payload.userId) {
            return { ...user, isEnabled: true }
          }
          return user
        }),
        meta: state.meta,
      }),
    },
    [DELETE_TENANT_ADMIN_USERS_RESPONSE]: {
      next: (state, action) => ({
        users: state.users.filter(
          user => user.platformUserId !== action.payload.userId,
        ),
        meta: state.meta,
      }),
    },
  },
  { items: [], meta: {} },
)

export default tenantAdminUsers
