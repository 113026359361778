import { handleActions } from 'redux-actions'
import {
  DISABLE_PASSPORT_USERS_RESPONSE,
  GET_PASSPORT_USERS_RESPONSE,
  ENABLE_PASSPORT_USERS_RESPONSE,
} from '../actions/types'

const passportUsersUi = handleActions(
  {
    [GET_PASSPORT_USERS_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
        isError: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
    [DISABLE_PASSPORT_USERS_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
        isError: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
    [ENABLE_PASSPORT_USERS_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
        isError: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
  },
  {},
)

export default passportUsersUi
