import { handleActions } from 'redux-actions'
import {
  ADD_CONNECTION_RESPONSE,
  ADD_CONNECTION_REQUEST,
} from '../actions/types'

const addConnectionUi = handleActions(
  {
    [ADD_CONNECTION_REQUEST]: () => ({
      isLoading: true,
    }),
    [ADD_CONNECTION_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
  },
  {},
)

const getIsLoading = state => state.ui.addConnection.isLoading
const getIsSuccessful = state => state.ui.addConnection.isSuccessful
const getIsError = state => state.ui.addConnection.isError
const getError = state => state.ui.addConnection.error

export { addConnectionUi, getIsSuccessful, getIsLoading, getIsError, getError }
