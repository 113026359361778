import React from 'react'
import PropTypes from 'prop-types'
import style from './Buttons.module.scss'

const ButtonPrimary = ({ type, disabled, onClick, children }) => (
  <button
    tabIndex={-1}
    type={type}
    className={`${style.btn} ${style.btnPrimary} ${'btn btn-primary'}`}
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </button>
)

ButtonPrimary.propTypes = {
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

ButtonPrimary.defaultProps = {
  disabled: false,
  type: 'button',
  onClick: () => {},
}

export default ButtonPrimary
