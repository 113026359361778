import { handleActions } from 'redux-actions'
import {
  DISABLE_ANCON_ADMIN_USERS_RESPONSE,
  GET_ANCON_ADMIN_USERS_RESPONSE,
  ENABLE_ANCON_ADMIN_USERS_RESPONSE,
} from '../actions/types'

const anconAdminUsersUi = handleActions(
  {
    [GET_ANCON_ADMIN_USERS_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
        isError: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
    [DISABLE_ANCON_ADMIN_USERS_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
        isError: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
    [ENABLE_ANCON_ADMIN_USERS_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
        isError: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
  },
  {},
)

export default anconAdminUsersUi
