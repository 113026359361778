import { handleActions } from 'redux-actions'
import {
  GET_LICENSES_RESPONSE,
  DISABLE_LICENSE_RESPONSE,
  DELETE_LICENSE_RESPONSE,
} from '../actions/types'

const licenses = handleActions(
  {
    [GET_LICENSES_RESPONSE]: {
      next: (state, action) => ({
        licenses: action.payload.data.items,
        meta: {
          ...state.meta,
          ...action.payload.data.meta,
          resultsPerPage: action.payload.meta.limit,
        },
      }),
    },
    [DISABLE_LICENSE_RESPONSE]: {
      next: (state, action) => ({
        licenses: state.licenses.map(license => {
          if (license.id === action.payload.licenseId) {
            return { ...license, isEnabled: !action.payload.status }
          }
          return license
        }),
        meta: state.meta,
      }),
    },
    [DELETE_LICENSE_RESPONSE]: {
      next: (state, action) => ({
        licenses: state.licenses.filter(
          license => license.id !== action.payload.licenseId,
        ),
        meta: state.meta,
      }),
    },
  },
  { licenses: [], meta: {} },
)

const getLicenseList = state => state.licenses.licenses
const getLicenseMeta = state => state.licenses.meta

export { getLicenseList, getLicenseMeta }
export default licenses
