// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pbvHl-tvQizIjMwPFq-Cww\\=\\={background-color:#f2f3fa;width:100vw;height:100vh;display:flex;align-items:center;justify-content:center}@media screen and (max-width: 768px){.pbvHl-tvQizIjMwPFq-Cww\\=\\={padding:25px}}", "",{"version":3,"sources":["webpack://src/modules/login/pages/authPage/AuthPage.module.scss"],"names":[],"mappings":"AAAA,4BAAY,wBAAwB,CAAC,WAAW,CAAC,YAAY,CAAC,YAAY,CAAC,kBAAkB,CAAC,sBAAsB,CAAC,qCAAqC,4BAAY,YAAY,CAAC","sourcesContent":[".background{background-color:#f2f3fa;width:100vw;height:100vh;display:flex;align-items:center;justify-content:center}@media screen and (max-width: 768px){.background{padding:25px}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": "pbvHl-tvQizIjMwPFq-Cww=="
};
export default ___CSS_LOADER_EXPORT___;
