import { handleActions } from 'redux-actions'
import {
  DELETE_LICENSE_RESPONSE,
  DELETE_LICENSE_REQUEST,
} from '../actions/types'

const deleteLicenseUi = handleActions(
  {
    [DELETE_LICENSE_REQUEST]: () => ({
      isLoading: true,
      isSuccessful: false,
    }),
    [DELETE_LICENSE_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
  },
  {},
)

const getIsDeleteLoading = state => state.ui.license.deleteLicense.isLoading
const getIsDeleteSuccessful = state =>
  state.ui.license.deleteLicense.isSuccessful
const getIsDeleteError = state => state.ui.license.deleteLicense.isError
const getDeleteError = state => state.ui.license.deleteLicense.error
const getDeleteLicenseUi = state => state.ui.license.deleteLicense

export {
  getIsDeleteSuccessful,
  getIsDeleteLoading,
  getIsDeleteError,
  getDeleteError,
  getDeleteLicenseUi,
}
export default deleteLicenseUi
