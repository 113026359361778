import { handleActions } from 'redux-actions'
import 
{ EDIT_IDENTITY_USER_REQUEST, EDIT_IDENTITY_USER_RESPONSE } from '../actions/types'

const editIdentityUserUi = handleActions(
  {
    [EDIT_IDENTITY_USER_REQUEST]: () => ({
      isLoading: true,
    }),
    [EDIT_IDENTITY_USER_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
  },
  {},
)

const getIsLoading = state => state.ui.editUser.isLoading
const getIsSuccessful = state => state.ui.editUser.isSuccessful
const getIsError = state => state.ui.editUser.isError
const getError = state => state.ui.editUser.error

export {
  editIdentityUserUi,
  getIsSuccessful,
  getIsLoading,
  getIsError,
  getError,
}
