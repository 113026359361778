import React, { PureComponent } from 'react'
import { Row, Col } from 'reactstrap'
import PropTypes from 'prop-types'
import RadioButton from './RadioButton'

class RadioButtons extends PureComponent {
  constructor(props) {
    super(props)
    const { selectedOption, options } = props
    this.state = {
      selectedOption: selectedOption || options[0].value,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.selectedOption !== nextProps.selectedOption) {
      return {
        selectedOption: nextProps.selectedOption
          ? nextProps.selectedOption
          : nextProps.options[0].value,
      }
    }
    return null
  }

  handleOnSelect = event => {
    const { onSelect } = this.props
    this.setState({
      selectedOption: event.target.value,
    })
    onSelect(event)
  }

  render() {
    const { selectedOption } = this.state
    const { options, layout, colSize } = this.props
    return (
      <Row className={layout === 'vertical' ? '' : ''}>
        {options.map(option => (
          <Col md={colSize} sm={colSize} key={option.value}>
            <RadioButton
              value={option.value}
              label={option.label}
              checked={selectedOption === option.value}
              onSelect={this.handleOnSelect}
            />
            {option.body ? option.body : ''}
          </Col>
        ))}
      </Row>
    )
  }
}

RadioButtons.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  colSize: PropTypes.number,
  selectedOption: PropTypes.string,
  layout: PropTypes.oneOf(['horizontal', 'vertical']),
  onSelect: PropTypes.func,
}

RadioButtons.defaultProps = {
  selectedOption: undefined,
  colSize: 12,
  layout: 'horizontal',
  onSelect: () => {},
}

export default RadioButtons
