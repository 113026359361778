import React, { Component } from 'react'
import { connect } from 'react-redux'
import DocumentTitle from 'Components/DocumentTitle'
import AuthDialog from '../components/AuthDialog'

class AuthContainer extends Component {
  static propTypes = {
    // currentOutlet: PropTypes.shape({}),
    // match: PropTypes.shape()
  }

  static defaultProps = {
    // currentOutlet: {},
    // match: {}
  }

  state = {}

  componentDidUpdate() {
    // const { match } = this.props;
    // const { match: prevMatch } = prevProps;
    // if (match.params.id !== prevMatch.params.id) {
    //   this.forceRefresh();
    // }
  }

  render() {
    return (
      <DocumentTitle title="Sign in">
        <AuthDialog />
      </DocumentTitle>
    )
  }
}

const mapStateToProps = () => ({})
export default connect(mapStateToProps)(AuthContainer)
