import { handleActions } from 'redux-actions'
import { combineReducers } from 'redux'
import {
  GET_ANCON_ADMIN_USERS_REQUEST,
  GET_ANCON_ADMIN_USERS_RESPONSE,
  GET_TENANT_ADMIN_USERS_REQUEST,
  GET_TENANT_ADMIN_USERS_RESPONSE,
  GET_PASSPORT_USERS_REQUEST,
  GET_PASSPORT_USERS_RESPONSE,
} from '../actions/types'
import { userTypes } from '../../../common/constants'

const getAnconAdminsUi = handleActions(
  {
    [GET_ANCON_ADMIN_USERS_REQUEST]: () => ({
      isLoading: true,
    }),
    [GET_ANCON_ADMIN_USERS_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
  },
  {},
)

const getTenantAdminsUi = handleActions(
  {
    [GET_TENANT_ADMIN_USERS_REQUEST]: () => ({
      isLoading: true,
    }),
    [GET_TENANT_ADMIN_USERS_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
  },
  {},
)

const getPassportHoldersUi = handleActions(
  {
    [GET_PASSPORT_USERS_REQUEST]: () => ({
      isLoading: true,
    }),
    [GET_PASSPORT_USERS_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
  },
  {},
)

const getUsersUi = combineReducers({
  getAnconAdmins: getAnconAdminsUi,
  getTenantAdmins: getTenantAdminsUi,
  getPassportHolders: getPassportHoldersUi,
})

const getIsLoading = (state, userType) => {
  switch (userType) {
    case userTypes.ANCON_ADMIN:
      return state.ui.getUsers.getAnconAdmins.isLoading
    case userTypes.PASSPORT:
      return state.ui.getUsers.getPassportHolders.isLoading
    case userTypes.TENANT_ADMIN:
      return state.ui.getUsers.getTenantAdmins.isLoading
    default:
      return true
  }
}
const getIsSuccessful = (state, userType) => {
  switch (userType) {
    case userTypes.ANCON_ADMIN:
      return state.ui.getUsers.getAnconAdmins.isSuccessful
    case userTypes.PASSPORT:
      return state.ui.getUsers.getPassportHolders.isSuccessful
    case userTypes.TENANT_ADMIN:
      return state.ui.getUsers.getTenantAdmins.isSuccessful
    default:
      return false
  }
}
const getIsError = (state, userType) => {
  switch (userType) {
    case userTypes.ANCON_ADMIN:
      return state.ui.getUsers.getAnconAdmins.isError
    case userTypes.PASSPORT:
      return state.ui.getUsers.getPassportHolders.isError
    case userTypes.TENANT_ADMIN:
      return state.ui.getUsers.getTenantAdmins.isError
    default:
      return true
  }
}
const getError = state => state.ui.currentUser.error

export { getUsersUi, getIsSuccessful, getIsLoading, getIsError, getError }
