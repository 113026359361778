import { handleActions } from 'redux-actions'
import {
  PUBLISH_BROADCAST_MESSAGE_REQUEST,
  PUBLISH_BROADCAST_MESSAGE_RESPONSE,
} from '../actions/types'

const publishBroadcastMessageUi = handleActions(
  {
    [PUBLISH_BROADCAST_MESSAGE_REQUEST]: {
      next: () => ({
        isLoading: true,
        isSuccessful: false,
        isError: false,
      }),
      throw: () => ({
        isLoading: false,
        isSuccessful: false,
        isError: true,
      }),
    },
    [PUBLISH_BROADCAST_MESSAGE_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
        isError: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: true,
        error: action.payload.message,
      }),
    },
  },
  {
    isLoading: false,
    isSuccessful: false,
    isError: false,
  },
)

export default publishBroadcastMessageUi
