import { handleActions } from 'redux-actions'
import {
  DISABLE_LICENSE_RESPONSE,
  DISABLE_LICENSE_REQUEST,
} from '../actions/types'

const disableLicenseUi = handleActions(
  {
    [DISABLE_LICENSE_REQUEST]: () => ({
      isLoading: true,
      isSuccessful: false,
    }),
    [DISABLE_LICENSE_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
  },
  {},
)

const getIsDisableLoading = state => state.ui.license.disableLicense.isLoading
const getIsDisableSuccessful = state =>
  state.ui.license.disableLicense.isSuccessful
const getIsDisableError = state => state.ui.license.disableLicense.isError
const getDisableError = state => state.ui.license.disableLicense.error

export {
  getIsDisableSuccessful,
  getIsDisableLoading,
  getIsDisableError,
  getDisableError,
}
export default disableLicenseUi
