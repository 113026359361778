import { handleActions } from 'redux-actions'
import {
  DELETE_TENANT_CONNECTION_RESPONSE,
  GET_USER_CONNECTIONS_TENANTS_RESPONSE,
} from '../actions/types'

const tenantConnections = handleActions(
  {
    [GET_USER_CONNECTIONS_TENANTS_RESPONSE]: {
      next: (state, action) => ({
        tenantConnections: action.payload.tenantConnections,
        meta: {
          ...state.meta,
          ...action.payload.meta,
          resultsPerPage: action.payload.meta.limit,
        },
      }),
    },
    [DELETE_TENANT_CONNECTION_RESPONSE]: {
      next: (state, action) => ({
        tenantConnections: state.tenantConnections.filter(
          connection => connection.tenantId !== action.payload.tenantId,
        ),
        meta: state.meta,
      }),
    },
    // [SET_RESULTS_PER_TENANTS_PAGE]: (state, action) => ({
    //   tenants: state.tenants,
    //   meta: { ...state.meta, resultsPerPage: action.payload }
    // })
  },
  { tenantConnections: [], meta: {} },
)

export const getTenantConnections = state =>
  state.tenantConnections.tenantConnections
export const getTenantConnectionsMeta = state => state.tenantConnections.meta

export default tenantConnections
