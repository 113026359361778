import { handleActions } from 'redux-actions'
import { ADD_OUTLET_RESPONSE } from '../actions/types'

const createOutlet = handleActions(
  {
    [ADD_OUTLET_RESPONSE]: (_, action) => action.payload,
  },
  {},
)

const getCreateOutlet = state => state.createOutlet

export default createOutlet
export { getCreateOutlet }
