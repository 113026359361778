import { handleActions } from 'redux-actions'
import { EDIT_OUTLET_REQUEST, EDIT_OUTLET_RESPONSE } from '../actions/types'

const editOutletUi = handleActions(
  {
    [EDIT_OUTLET_REQUEST]: () => ({
      isLoading: true,
    }),
    [EDIT_OUTLET_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload,
      }),
    },
  },
  {},
)

const getIsLoading = state => state.ui.editOutlet.isLoading
const getIsSuccessful = state => state.ui.editOutlet.isSuccessful
const getIsError = state => state.ui.editOutlet.isError
const getError = state => state.ui.editOutlet.error

export { editOutletUi, getIsSuccessful, getIsLoading, getIsError, getError }
