import React from 'react'
import PropTypes from 'prop-types'
import { ToggleButton } from '..'
import style from './ToggleLabel.module.scss'

const ToggleLabel = ({ children, ...rest }) => (
  <div className={style.label}>
    <p>{children}</p>
    <ToggleButton {...rest} />
  </div>
)

ToggleLabel.propTypes = {
  children: PropTypes.node.isRequired,
  onToggle: PropTypes.func.isRequired,
}

export default ToggleLabel
