import { handleActions } from 'redux-actions'
import {
  DISABLE_ANCON_ADMIN_USERS_RESPONSE,
  GET_ANCON_ADMIN_USERS_RESPONSE,
  ENABLE_ANCON_ADMIN_USERS_RESPONSE,
  DELETE_ANCON_ADMIN_USERS_RESPONSE,
} from '../actions/types'

const anconAdminUsers = handleActions(
  {
    [GET_ANCON_ADMIN_USERS_RESPONSE]: {
      next: (state, action) => action.payload,
    },
    [DISABLE_ANCON_ADMIN_USERS_RESPONSE]: {
      next: (state, action) => {
        const users = state.users.map(user => {
          if (user.platformUserId === action.payload.userId) {
            return { ...user, isEnabled: false }
          }
          return user
        })
        return {
          users,
          meta: state.meta,
        }
      },
    },
    [ENABLE_ANCON_ADMIN_USERS_RESPONSE]: {
      next: (state, action) => ({
        users: state.users.map(user => {
          if (user.platformUserId === action.payload.userId) {
            return { ...user, isEnabled: true }
          }
          return user
        }),
        meta: state.meta,
      }),
    },
    [DELETE_ANCON_ADMIN_USERS_RESPONSE]: {
      next: (state, action) => ({
        users: state.users.filter(
          user => user.platformUserId !== action.payload.userId,
        ),
        meta: state.meta,
      }),
    },
  },
  { items: [], meta: {} },
)

export default anconAdminUsers
