import { createAction } from 'redux-actions'

import api from '../../../common/api'
import { getUrl } from '../../../common/constants'
import { GET_CONTEXT_REQUEST, GET_CONTEXT_RESPONSE } from '.'

const getContextRequest = createAction(GET_CONTEXT_REQUEST)
const getContextResponse = createAction(GET_CONTEXT_RESPONSE)

export function getContext() {
  return dispatch => {
    dispatch(getContextRequest())
    api
      .get(getUrl('/ClientContexts'))
      .then(clientContextResponse => {
        const identityUserId =
          clientContextResponse.data?.adminUser?.identityUserId

        if (!identityUserId) {
          throw new Error('Identity user ID for admin user not found')
        }

        api
          .get(getUrl(`/IdentityUsers/${identityUserId}`))
          .then(identityUserResponse => {
            dispatch(
              getContextResponse({
                clientContext: clientContextResponse.data,
                identityUser: identityUserResponse.data,
              }),
            )
          })
          .catch(err => {
            dispatch(getContextResponse(err))
          })
      })
      .catch(err => {
        dispatch(getContextResponse(err))
      })
  }
}
