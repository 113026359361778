import React from 'react'
import { Route, Redirect, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import userManager from '../common/userManager'
import RouteTypes from './Navigation/RouteTypes'

const PrivateRoute = ({ component: Component, render, location, ...rest }) => {
  const renderComponent = props => {
    if (render) {
      return render(props)
    }
    return <Component {...props} />
  }
  return (
    <Route
      {...rest}
      render={props =>
        userManager.isAuthenticated() ? (
          renderComponent(props)
        ) : (
          <Redirect
            to={{
              pathname: RouteTypes.login,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({}),
    PropTypes.node,
  ]),
  render: PropTypes.func,
  location: PropTypes.objectOf(PropTypes.shape).isRequired,
}

PrivateRoute.defaultProps = {
  render: undefined,
  component: <div />,
}

export default withRouter(PrivateRoute)
