import { handleActions } from 'redux-actions'
import {
  GET_CUSTOMERS_RESPONSE,
  DISABLE_CUSTOMERS_RESPONSE,
  ENABLE_CUSTOMERS_RESPONSE,
} from '../actions/types'

const customersUi = handleActions(
  {
    [GET_CUSTOMERS_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
        isError: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
    [DISABLE_CUSTOMERS_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
        isError: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
    [ENABLE_CUSTOMERS_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
        isError: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
  },
  {},
)

export default customersUi
