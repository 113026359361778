import React from 'react'
import styles from './Spinner.module.scss'

const Spinner = () => (
  <>
    <div className={styles.spinner} role="status" />
  </>
)

export default Spinner
