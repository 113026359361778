import { handleActions } from 'redux-actions'
import {
  GET_CUSTOMERS_RESPONSE,
  DISABLE_CUSTOMERS_RESPONSE,
  ENABLE_CUSTOMERS_RESPONSE,
  DELETE_CUSTOMERS_RESPONSE,
} from '../actions/types'

const customers = handleActions(
  {
    [GET_CUSTOMERS_RESPONSE]: {
      next: (state, action) => action.payload,
    },
    [DISABLE_CUSTOMERS_RESPONSE]: {
      next: (state, action) => ({
        users: state.users.map(user => {
          if (user.platformUserId === action.payload.userId) {
            return { ...user, isEnabled: false }
          }
          return user
        }),
        meta: state.meta,
      }),
    },
    [ENABLE_CUSTOMERS_RESPONSE]: {
      next: (state, action) => ({
        users: state.users.map(user => {
          if (user.platformUserId === action.payload.userId) {
            return { ...user, isEnabled: true }
          }
          return user
        }),
        meta: state.meta,
      }),
    },
    [DELETE_CUSTOMERS_RESPONSE]: {
      next: (state, action) => ({
        users: state.users.filter(
          user => user.platformUserId !== action.payload.userId,
        ),
        meta: state.meta,
      }),
    },
  },
  { items: [], meta: {} },
)

export default customers
