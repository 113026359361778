import { handleActions } from 'redux-actions'
import { GET_CONTEXT_RESPONSE } from '../actions'

const context = handleActions(
  {
    [GET_CONTEXT_RESPONSE]: {
      next: (_, { payload }) => ({
        clientContext: payload.clientContext,
        identityUser: payload.identityUser,
      }),
    },
  },
  {
    clientContext: null,
    identityUser: null,
  },
)

export default context
