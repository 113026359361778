import { handleActions } from 'redux-actions'

import {
  CLEAR_OUTLET_ADMINS,
  GET_OUTLET_ADMINS_REQUEST,
  GET_OUTLET_ADMINS_RESPONSE,
} from '../actions/types'

const currentOutletAdmins = handleActions(
  {
    [GET_OUTLET_ADMINS_RESPONSE]: {
      next: (_, { payload }) => payload.data,
    },
    [CLEAR_OUTLET_ADMINS]: () => ({}),
  },
  {},
)

const currentOutletAdminsUi = handleActions(
  {
    [GET_OUTLET_ADMINS_REQUEST]: () => ({
      isLoading: true,
    }),
    [GET_OUTLET_ADMINS_RESPONSE]: {
      next: () => ({
        isLoading: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload,
      }),
    },
    [CLEAR_OUTLET_ADMINS]: {
      next: state => {
        if (state.error) {
          if (state.error.status === 404) {
            return state
          }
          return {}
        }
        return {}
      },
    },
  },
  { admins: [] },
)

const getIsLoading = state => state.ui.currentOutletAdmins.isLoading
const getCurrentOutletAdmins = state => state.currentOutletAdmins.admins
const getIsError = state => state.ui.currentOutletAdmins.isError
const getError = state => state.ui.currentOutletAdmins.error

export {
  currentOutletAdminsUi,
  getIsLoading,
  getCurrentOutletAdmins,
  getIsError,
  getError,
}
export default currentOutletAdmins
