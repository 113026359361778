import { createAction } from 'redux-actions'
import api from '../../../common/api'

import {
  getUrl,
  DEFAULT_RESULTS_LIMIT,
  DEFAULT_RESULTS_OFFSET,
  DEFAULT_ORDER_BY,
  DEFAULT_ORDER_DIRECTION,
  endpoint,
} from '../../../common/constants'

import {
  GET_LICENSES_REQUEST,
  GET_LICENSES_RESPONSE,
  GET_LICENSE_DETAILS_RESPONSE,
  GET_LICENSE_DETAILS_REQUEST,
  ADD_LICENSE_REQUEST,
  ADD_LICENSE_RESPONSE,
  CLEAR_LICENSE_DETAILS,
  CLEAR_UI,
  EDIT_LICENSE_REQUEST,
  EDIT_LICENSE_RESPONSE,
  DISABLE_LICENSE_REQUEST,
  DISABLE_LICENSE_RESPONSE,
  DELETE_LICENSE_REQUEST,
  DELETE_LICENSE_RESPONSE,
  GET_SEARCH_LICENSES_REQUEST,
  GET_SEARCH_LICENSES_RESPONSE,
  DISABLE_SEARCH_LICENSE_REQUEST,
  DISABLE_SEARCH_LICENSE_RESPONSE,
  DELETE_SEARCH_LICENSE_REQUEST,
  DELETE_SEARCH_LICENSE_RESPONSE,
} from './types'

const getLicenseRequest = createAction(GET_LICENSES_REQUEST)
const getLicenseResponse = createAction(GET_LICENSES_RESPONSE)
export const fetchLicenses = (
  _,
  {
    limit = DEFAULT_RESULTS_LIMIT,
    offset = DEFAULT_RESULTS_OFFSET,
    orderBy = DEFAULT_ORDER_BY,
    orderDirection = DEFAULT_ORDER_DIRECTION,
  },
) => {
  const url = getUrl(
    `${endpoint.PACKAGES_USAGE}?limit=${limit}&offset=${offset}&order=${orderBy}:${orderDirection}`,
  )
  return dispatch => {
    dispatch(getLicenseRequest())
    api
      .get(url)
      .then(response => {
        dispatch(
          getLicenseResponse({
            data: response.data,
            meta: { limit, offset },
          }),
        )
      })
      .catch(err => {
        dispatch(getLicenseResponse(err))
      })
  }
}

const getSearchLicenseRequest = createAction(GET_SEARCH_LICENSES_REQUEST)
const getSearchLicenseResponse = createAction(GET_SEARCH_LICENSES_RESPONSE)
export const fetchSearchLicenses = ({
  text,
  offset = DEFAULT_RESULTS_OFFSET,
  limit = DEFAULT_RESULTS_LIMIT,
  orderBy = DEFAULT_ORDER_BY,
  orderDirection = DEFAULT_ORDER_DIRECTION,
}) => {
  const url = getUrl(
    `${endpoint.PACKAGES_USAGE}?search=${text}&limit=${limit}&offset=${offset}&order=${orderBy}:${orderDirection}`,
  )
  return dispatch => {
    dispatch(getSearchLicenseRequest())
    api
      .get(url)
      .then(response => {
        dispatch(
          getSearchLicenseResponse({
            data: response.data,
            meta: { limit, offset },
          }),
        )
      })
      .catch(err => {
        dispatch(getSearchLicenseResponse(err))
      })
  }
}

const getLicenseDetailsRequest = createAction(GET_LICENSE_DETAILS_REQUEST)
const getLicenseDetailsResponse = createAction(GET_LICENSE_DETAILS_RESPONSE)
export const getLicenseDetails = licenseId => {
  const url = getUrl(`${endpoint.PACKAGES_USAGE}/${licenseId}`)
  return dispatch => {
    dispatch(getLicenseDetailsRequest())
    api
      .get(url)
      .then(response => {
        dispatch(getLicenseDetailsResponse(response))
      })
      .catch(err => dispatch(getLicenseDetailsResponse(err)))
  }
}

const addLicenseRequest = createAction(ADD_LICENSE_REQUEST)
const addLicenseResponse = createAction(ADD_LICENSE_RESPONSE)
export const addLicense = (licenses, module) => {
  const requestBody = { name: licenses.name, modules: module }

  return dispatch => {
    dispatch(addLicenseRequest())
    api
      .post(endpoint.PACKAGES_USAGE, requestBody)
      .then(response => {
        dispatch(addLicenseResponse(response.data))
      })
      .catch(err => {
        dispatch(addLicenseResponse(err))
      })
  }
}

const editLicenseRequest = createAction(EDIT_LICENSE_REQUEST)
const editLicenseResponse = createAction(EDIT_LICENSE_RESPONSE)
export const editLicense = (licenses, module, licenseId) => {
  const requestBody = {
    id: licenseId,
    name: licenses.name,
    modules: module,
  }

  const url = getUrl(`${endpoint.PACKAGES}/${licenseId}`)
  return dispatch => {
    dispatch(editLicenseRequest())
    api
      .put(url, requestBody)
      .then(response => {
        dispatch(editLicenseResponse(response.data))
      })
      .catch(err => {
        dispatch(editLicenseResponse(err))
      })
  }
}

export const clearCurrentLicense = createAction(CLEAR_LICENSE_DETAILS)
export const clearUiState = createAction(CLEAR_UI)

const availabilityAction = Object.freeze({
  DELETE: 'delete',
  DISABLE: 'disable',
})
const modifyAvailabilityLicenseStatus = (
  licenseId,
  requestActionFunc,
  responseActionFunc,
  actionType,
  status,
) => dispatch => {
  dispatch(requestActionFunc())

  if (actionType === availabilityAction.DISABLE) {
    const url = getUrl(`${endpoint.PACKAGES}/${licenseId}/${actionType}`)
    const body = {
      [actionType]: status,
    }
    api
      .put(url, body)
      .then(() => dispatch(responseActionFunc({ licenseId, status })))
      .catch(err => dispatch(responseActionFunc(err)))
  } else {
    const url = getUrl(`${endpoint.PACKAGES}/${licenseId}`)
    api
      .delete(url)
      .then(() => dispatch(responseActionFunc({ licenseId, status })))
      .catch(err => dispatch(responseActionFunc(err)))
  }
}

const disableLicenseRequest = createAction(DISABLE_LICENSE_REQUEST)
const disableLicenseResponse = createAction(DISABLE_LICENSE_RESPONSE)
export const disableLicense = (licenseId, status = true) =>
  modifyAvailabilityLicenseStatus(
    licenseId,
    disableLicenseRequest,
    disableLicenseResponse,
    availabilityAction.DISABLE,
    status,
  )

const deleteLicenseRequest = createAction(DELETE_LICENSE_REQUEST)
const deleteLicenseResponse = createAction(DELETE_LICENSE_RESPONSE)
export const deleteLicense = (licenseId, status = true) =>
  modifyAvailabilityLicenseStatus(
    licenseId,
    deleteLicenseRequest,
    deleteLicenseResponse,
    availabilityAction.DELETE,
    status,
  )

const disableSearchLicenseRequest = createAction(DISABLE_SEARCH_LICENSE_REQUEST)
const disableSearchLicenseResponse = createAction(
  DISABLE_SEARCH_LICENSE_RESPONSE,
)
export const disableSearchLicense = (licenseId, status = true) =>
  modifyAvailabilityLicenseStatus(
    licenseId,
    disableSearchLicenseRequest,
    disableSearchLicenseResponse,
    availabilityAction.DISABLE,
    status,
  )

const deleteSearchLicenseRequest = createAction(DELETE_SEARCH_LICENSE_REQUEST)
const deleteSearchLicenseResponse = createAction(DELETE_SEARCH_LICENSE_RESPONSE)
export const deleteSearchLicense = (licenseId, status = true) =>
  modifyAvailabilityLicenseStatus(
    licenseId,
    deleteSearchLicenseRequest,
    deleteSearchLicenseResponse,
    availabilityAction.DELETE,
    status,
  )

export default {}
