import { handleActions } from 'redux-actions'
import {
  GET_LICENSES_PER_OUTLET_REQUEST,
  GET_LICENSES_PER_OUTLET_RESPONSE,
} from '../actions/types'

const outletLicenses = handleActions(
  {
    [GET_LICENSES_PER_OUTLET_RESPONSE]: {
      next: (_, { payload }) => payload.items,
    },
  },
  [],
)

const outletLicensesUi = handleActions(
  {
    [GET_LICENSES_PER_OUTLET_REQUEST]: () => ({
      isLoading: true,
    }),
    [GET_LICENSES_PER_OUTLET_RESPONSE]: {
      next: () => ({
        isLoading: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
  },
  {},
)

const getIsLoading = state => state.ui.outletLicenses.isLoading
const getIsError = state => state.ui.outletLicenses.isError
const getError = state => state.ui.outletLicenses.error

export { outletLicensesUi, getIsLoading, getIsError, getError }
export default outletLicenses
