import { handleActions } from 'redux-actions'
import { ADD_OUTLET_REQUEST, ADD_OUTLET_RESPONSE } from '../actions/types'

const addOutletUi = handleActions(
  {
    [ADD_OUTLET_REQUEST]: () => ({
      isLoading: true,
    }),
    [ADD_OUTLET_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
  },
  {},
)

const getIsLoading = state => state.ui.addOutlet.isLoading
const getIsSuccessful = state => state.ui.addOutlet.isSuccessful
const getIsError = state => state.ui.addOutlet.isError
const getError = state => state.ui.addOutlet.error

export { addOutletUi, getIsSuccessful, getIsLoading, getIsError, getError }
