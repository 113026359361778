// List
const GET_BROADCAST_MESSAGES_REQUEST = 'GET_BROADCAST_MESSAGES_REQUEST'
const GET_BROADCAST_MESSAGES_RESPONSE = 'GET_BROADCAST_MESSAGES_RESPONSE'

// Delete
const DELETE_BROADCAST_MESSAGE_REQUEST = 'DELETE_BROADCAST_MESSAGE_REQUEST'
const DELETE_BROADCAST_MESSAGE_RESPONSE = 'DELETE_BROADCAST_MESSAGE_RESPONSE'

// Add
const ADD_BROADCAST_MESSAGE_REQUEST = 'ADD_BROADCAST_MESSAGE_REQUEST'
const ADD_BROADCAST_MESSAGE_RESPONSE = 'ADD_BROADCAST_MESSAGE_RESPONSE'

// Current
const GET_CURRENT_BROADCAST_MESSAGE_REQUEST =
  'GET_CURRENT_BROADCAST_MESSAGE_REQUEST'
const GET_CURRENT_BROADCAST_MESSAGE_RESPONSE =
  'GET_CURRENT_BROADCAST_MESSAGE_RESPONSE'
const CLEAR_CURRENT_BROADCAST_MESSAGE = 'CLEAR_CURRENT_BROADCAST_MESSAGE'

// Publish
const PUBLISH_BROADCAST_MESSAGE_REQUEST = 'PUBLISH_BROADCAST_MESSAGE_REQUEST'
const PUBLISH_BROADCAST_MESSAGE_RESPONSE = 'PUBLISH_BROADCAST_MESSAGE_RESPONSE'

// Clear UI
const CLEAR_UI = 'CLEAR_UI'

export {
  GET_BROADCAST_MESSAGES_REQUEST,
  GET_BROADCAST_MESSAGES_RESPONSE,
  DELETE_BROADCAST_MESSAGE_REQUEST,
  DELETE_BROADCAST_MESSAGE_RESPONSE,
  ADD_BROADCAST_MESSAGE_REQUEST,
  ADD_BROADCAST_MESSAGE_RESPONSE,
  GET_CURRENT_BROADCAST_MESSAGE_REQUEST,
  GET_CURRENT_BROADCAST_MESSAGE_RESPONSE,
  CLEAR_CURRENT_BROADCAST_MESSAGE,
  PUBLISH_BROADCAST_MESSAGE_REQUEST,
  PUBLISH_BROADCAST_MESSAGE_RESPONSE,
  CLEAR_UI,
}
