import React from 'react'
import PropTypes from 'prop-types'
import styles from './PageHeader.module.scss'
import { BackButton } from '..'

const PageHeader = ({ backEnabled, title, children }) => (
  <div className={styles.pageTitle}>
    {backEnabled ? <BackButton /> : ''}
    <h1>{title}</h1>
    {children}
  </div>
)

PageHeader.defaultProps = {
  title: '',
  children: [],
  backEnabled: false,
}

PageHeader.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  backEnabled: PropTypes.bool,
}

export default PageHeader
