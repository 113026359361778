import { handleActions } from 'redux-actions'
import {
  SEARCH_ANCON_ADMIN_USERS_REQUEST,
  SEARCH_ANCON_ADMIN_USERS_RESPONSE,
  SEARCH_PASSPORT_USERS_REQUEST,
  SEARCH_PASSPORT_USERS_RESPONSE,
  SEARCH_TENANT_ADMIN_USERS_REQUEST,
  SEARCH_TENANT_ADMIN_USERS_RESPONSE,
  DISABLE_ANCON_ADMIN_USERS_RESPONSE,
  DISABLE_PASSPORT_USERS_RESPONSE,
  DISABLE_TENANT_ADMIN_USERS_RESPONSE,
  ENABLE_ANCON_ADMIN_SEARCH_USERS_RESPONSE,
  ENABLE_PASSPORT_SEARCH_USERS_RESPONSE,
  ENABLE_TENANT_ADMIN_SEARCH_USERS_RESPONSE,
  DELETE_ANCON_ADMIN_SEARCH_USERS_RESPONSE,
  DELETE_PASSPORT_SEARCH_USERS_RESPONSE,
  DELETE_TENANT_ADMIN_SEARCH_USERS_RESPONSE,
} from '../actions/types'

const searchUsers = handleActions(
  {
    [SEARCH_ANCON_ADMIN_USERS_RESPONSE]: {
      next: (_, { payload }) => ({ users: payload.data, meta: payload.meta }),
    },
    [SEARCH_PASSPORT_USERS_RESPONSE]: {
      next: (_, { payload }) => ({ users: payload.data, meta: payload.meta }),
    },
    [SEARCH_TENANT_ADMIN_USERS_RESPONSE]: {
      next: (_, { payload }) => ({ users: payload.data, meta: payload.meta }),
    },
    [DISABLE_ANCON_ADMIN_USERS_RESPONSE]: {
      next: (state, { payload }) => ({
        users: state.users.map(user => {
          if (user.platformUserId === payload.userId) {
            return { ...user, isEnabled: false }
          }
          return user
        }),
        meta: state.meta,
      }),
    },
    [DISABLE_PASSPORT_USERS_RESPONSE]: {
      next: (state, { payload }) => ({
        users: state.users.map(user => {
          if (user.platformUserId === payload.userId) {
            return { ...user, isEnabled: false }
          }
          return user
        }),
        meta: state.meta,
      }),
    },
    [DISABLE_TENANT_ADMIN_USERS_RESPONSE]: {
      next: (state, { payload }) => ({
        users: state.users.map(user => {
          if (user.platformUserId === payload.userId) {
            return { ...user, isEnabled: false }
          }
          return user
        }),
        meta: state.meta,
      }),
    },
    [ENABLE_ANCON_ADMIN_SEARCH_USERS_RESPONSE]: {
      next: (state, { payload }) => ({
        users: state.users.map(user => {
          if (user.platformUserId === payload.userId) {
            return { ...user, isEnabled: true }
          }
          return user
        }),
        meta: state.meta,
      }),
    },
    [ENABLE_PASSPORT_SEARCH_USERS_RESPONSE]: {
      next: (state, { payload }) => ({
        users: state.users.map(user => {
          if (user.platformUserId === payload.userId) {
            return { ...user, isEnabled: true }
          }
          return user
        }),
        meta: state.meta,
      }),
    },
    [ENABLE_TENANT_ADMIN_SEARCH_USERS_RESPONSE]: {
      next: (state, { payload }) => ({
        users: state.users.map(user => {
          if (user.platformUserId === payload.userId) {
            return { ...user, isEnabled: true }
          }
          return user
        }),
        meta: state.meta,
      }),
    },
    [DELETE_ANCON_ADMIN_SEARCH_USERS_RESPONSE]: {
      next: (state, { payload }) => ({
        users: state.users.filter(
          user => user.platformUserId !== payload.userId,
        ),
        meta: state.meta,
      }),
    },
    [DELETE_PASSPORT_SEARCH_USERS_RESPONSE]: {
      next: (state, { payload }) => ({
        users: state.users.filter(
          user => user.platformUserId !== payload.userId,
        ),
        meta: state.meta,
      }),
    },
    [DELETE_TENANT_ADMIN_SEARCH_USERS_RESPONSE]: {
      next: (state, { payload }) => ({
        users: state.users.filter(
          user => user.platformUserId !== payload.userId,
        ),
        meta: state.meta,
      }),
    },
  },
  { users: [], meta: {} },
)

const searchUsersUi = handleActions(
  {
    [SEARCH_ANCON_ADMIN_USERS_REQUEST]: () => ({
      isLoading: true,
    }),
    [SEARCH_ANCON_ADMIN_USERS_RESPONSE]: {
      next: () => ({
        isLoading: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
    [SEARCH_PASSPORT_USERS_REQUEST]: () => ({
      isLoading: true,
    }),
    [SEARCH_PASSPORT_USERS_RESPONSE]: {
      next: () => ({
        isLoading: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
    [SEARCH_TENANT_ADMIN_USERS_REQUEST]: () => ({
      isLoading: true,
    }),
    [SEARCH_TENANT_ADMIN_USERS_RESPONSE]: {
      next: () => ({
        isLoading: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
  },
  {},
)

const searchUsersActionsUi = handleActions(
  {
    [DISABLE_ANCON_ADMIN_USERS_RESPONSE]: {
      next: () => ({
        isLoading: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
    [DISABLE_PASSPORT_USERS_RESPONSE]: {
      next: () => ({
        isLoading: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
    [DISABLE_TENANT_ADMIN_USERS_RESPONSE]: {
      next: () => ({
        isLoading: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
    [ENABLE_ANCON_ADMIN_SEARCH_USERS_RESPONSE]: {
      next: () => ({
        isLoading: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
    [ENABLE_PASSPORT_SEARCH_USERS_RESPONSE]: {
      next: () => ({
        isLoading: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
    [ENABLE_TENANT_ADMIN_SEARCH_USERS_RESPONSE]: {
      next: () => ({
        isLoading: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
  },
  {},
)

const getSearchUsersUi = state => ({
  isLoading: state.ui.searchUsers.isLoading,
  isError: state.ui.searchUsers.isError,
  error: state.ui.searchUsers.error,
})

const getActionIsLoading = state => state.ui.searchUsersActions.isLoading
const getActionIsError = state => state.ui.searchUsersActions.isError
const getActionError = state => state.ui.searchUsersActions.error

const getUserList = state => state.search.users.users
const getUserMeta = state => state.search.users.meta

export {
  searchUsersUi,
  searchUsersActionsUi,
  getSearchUsersUi,
  getActionIsLoading,
  getActionIsError,
  getActionError,
  getUserList,
  getUserMeta,
}

export default searchUsers
