import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import style from './Buttons.module.scss'
import loader from './loader.gif'

function ButtonLoading({
  type,
  disabled,
  className,
  onClick,
  children,
  isLoading,
}) {
  return (
    <button
      tabIndex={-1}
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={classNames(
        style.btn,
        style.btnPrimary,
        'btn',
        'btn-primary',
        className,
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {isLoading ? (
        <img src={loader} alt="loader" height="40" width="40" />
      ) : (
        children
      )}
    </button>
  )
}

ButtonLoading.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']).isRequired,
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
}

ButtonLoading.defaultProps = {
  className: undefined,
  disabled: false,
  isLoading: false,
  onClick: () => {},
}

export default ButtonLoading
