import { handleActions } from 'redux-actions'
import {
  DELETE_SEARCH_LICENSE_RESPONSE,
  DELETE_SEARCH_LICENSE_REQUEST,
} from '../actions/types'

const deleteSearchLicenseUi = handleActions(
  {
    [DELETE_SEARCH_LICENSE_REQUEST]: () => ({
      isLoading: true,
      isSuccessful: false,
    }),
    [DELETE_SEARCH_LICENSE_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
  },
  {},
)

const getIsDeleteLoading = state =>
  state.ui.license.deleteSearchLicense.isLoading
const getIsDeleteSuccessful = state =>
  state.ui.license.deleteSearchLicense.isSuccessful
const getIsDeleteError = state => state.ui.license.deleteSearchLicense.isError
const getDeleteError = state => state.ui.license.deleteSearchLicense.error

const getDeleteSearchLicenseUi = state => state.ui.license.deleteSearchLicense

export {
  getIsDeleteSuccessful,
  getDeleteSearchLicenseUi,
  getIsDeleteLoading,
  getIsDeleteError,
  getDeleteError,
}
export default deleteSearchLicenseUi
