const getPaddedString = (outputLength, numberString, paddingChar = '0') => {
  if (outputLength <= numberString.length) {
    return numberString
  }
  return (numberString =
    paddingChar + getPaddedString(outputLength - 1, numberString))
}

/* const customEncodeURIComponent = text =>
  encodeURIComponent(
    encodeURIComponent(text)
  ); a single encodeURIComponent causes history api to fail giving out malformedURI exception for '%'
   : refer unfixed issue on ReactTraining https://github.com/ReactTraining/history/issues/505 */
/* further this causes unexpected results when searching because the text is double encoded,
 causes inconsistency with the actual search query AI-893 */

const customEncodeURIComponent = text => encodeURIComponent(text)

const guid = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`
}

const slugify = text =>
  text
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text

const getTime = number => {
  const hour = getPaddedString(2, number.toString())
  return `${hour}:00`
}

const getHour = time => parseInt(getTrimmedTime(time, 1), 10)

const getTrimmedTime = (time, index = 2) => trimStringWithChar(time, ':', index)

const trimStringWithChar = (string, char, index) =>
  string.split(char).slice(0, index).join(char)

const capitalizeFirstLetter = string =>
  string.charAt(0).toUpperCase() + string.slice(1)

const arrayHasDuplicates = array => new Set(array).size !== array.length

export default getPaddedString
export {
  getPaddedString,
  guid,
  getTime,
  getTrimmedTime,
  capitalizeFirstLetter,
  getHour,
  customEncodeURIComponent,
  arrayHasDuplicates,
  slugify,
}
