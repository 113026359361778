import firebase from 'firebase/app'
import 'firebase/auth'
import axiosApi, { setNewAccessToken } from './api'
import { getUrl } from './constants'

const TOKEN_KEY = 'access_token'

const firebaseConfig = {
  // apiKey: process.env.FIREBASE_API_KEY,
  // authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  // databaseURL: process.env.FIREBASE_DB_URL,
  // projectId: process.env.FIREBASE_PROJ_ID,
  // storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  // messagingSenderId: process.env.REACT_APP_DEV_MSG_SENDER_ID,
  // measurementId: process.env.FIREBASE_MEASUREMENT_ID

  apiKey: 'AIzaSyAwVPuipbzssJbYeGHnXIyeHjlBVmx4CF8',
  authDomain: 'leen-61727.firebaseapp.com',
  databaseURL: 'https://leen-61727.firebaseio.com',
  projectId: 'leen-61727',
  storageBucket: '',
  messagingSenderId: '544918921186',
  appId: '1:544918921186:web:2afe14329d6954034563bb',
  measurementId: 'G-SC4ENJMFCH',
}

firebase.initializeApp(firebaseConfig)

class userManager {
  setAccessToken = async token => {
    const userString = localStorage.getItem(this.oidcKey)
    if (!userString) {
      localStorage.setItem(this.oidcKey, JSON.stringify({ [TOKEN_KEY]: token }))
    }
    setNewAccessToken(token)
    await this.setCurrentIdentityUser()
  }

  removeAccessToken = () => {
    setNewAccessToken(null)
  }

  getCurrentIdentityUserId = async () => {
    const url = getUrl('/ClientContexts')

    try {
      const response = await axiosApi.get(url)
      const id = response.data.identityUser.identityUserId

      return id
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Caught error in getCurrentIdentityUserId', err)
    }
  }

  // firebase methods
  firebaseSignIn = async (email, password) => {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)

    await firebase.auth().signInWithEmailAndPassword(email, password)

    const token = await firebase.auth().currentUser.getIdToken()

    const { claims } = await firebase.auth().currentUser.getIdTokenResult()

    if (!claims.superadmin) {
      throw new Error('Access denied')
    }

    await this.getCurrentIdentityUserId()

    setNewAccessToken(token)
  }

  firebaseCurrentUserIdToken = async () => {
    const idToken = await firebase.auth().currentUser.getIdToken()
    return idToken
  }

  firebaseSignOut = async () => {
    try {
      await firebase.auth().signOut()
      this.removeAccessToken()
    } catch (err) {
      console.error('Failed to sign out') // eslint-disable-line no-console
      console.error(err) // eslint-disable-line no-console
    }
  }

  firebasePasswordReset = async email => {
    try {
      await firebase.auth().sendPasswordResetEmail(email, {})
    } catch (error) {
      console.log(`Error Code: ${error.code}`, `Message: ${error.message}`) // eslint-disable-line no-console
    }
  }

  isAuthenticated = () => {
    const user = firebase.auth().currentUser

    if (user) {
      return true
    }
    return false
  }
}

export default new userManager()
