import { combineReducers } from 'redux'
import locale from '../../modules/i18n/reducers/locale'
import tenants from '../../modules/tenant/reducers/tenants'
import {
  outlets,
  currentOutlet,
  createOutlet,
  currentOutletAdmins,
  outletLicenses,
} from '../../modules/outlet/reducers'
import {
  anconAdminUsers,
  passportUsers,
  tenantAdminUsers,
  currentUser,
} from '../../modules/users/reducers'
import currentTenant from '../../modules/tenant/reducers/currentTenant'
import ui from './uiState'
import search from './search'
import identityUser from '../../modules/login/reducers/identityUser'
import { CLEAR_UI } from '../../modules/outlet/actions/types'
import outletConnections from '../../modules/users/reducers/outletConnections'
import tenantConnections from '../../modules/users/reducers/tenantConnections'
import { licenses, currentLicense } from '../../modules/license/reducers'
import fileUploads from '../../modules/main/reducers/fileUploads'
import customers from '../../modules/users/reducers/customers'
import {
  broadcastMessages,
  currentBroadcastMessage,
} from '../../modules/broadcastMessages/reducers'
import { context } from '../../modules/context/reducers'

const dataReducer = combineReducers({
  locale,
  tenants,
  outlets,
  currentOutlet,
  createOutlet,
  currentTenant,
  currentOutletAdmins,
  search,
  anconAdminUsers,
  passportUsers,
  customers,
  tenantAdminUsers,
  currentUser,
  outletConnections,
  tenantConnections,
  licenses,
  currentLicense,
  outletLicenses,
  identityUser,
  broadcastMessages,
  currentBroadcastMessage,
  context,

  ui: (state = {}) => state,
  fileUploads: (state = {}) => state,
})

const rootReducer = (state = {}, action) => {
  if (action.type === CLEAR_UI) {
    state.ui = undefined
  }

  return {
    ...dataReducer(state, action),
    ui: ui(state, action),
    fileUploads: fileUploads(state.fileUploads, action),
  }
}

export default rootReducer
