import { handleActions } from 'redux-actions'
import { GET_IDENTITY_USER_RESPONSE } from '../actions/types'

const user = handleActions(
  {
    [GET_IDENTITY_USER_RESPONSE]: {
      next: (_, { payload }) => payload.data,
    },
  },
  {},
)

const getIdentityUser = state => state.identityUser

export { getIdentityUser }
export default user
