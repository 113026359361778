import { toast } from 'react-toastify'

const options = {
  className: 'ancon-toast',
  bodyClassName: 'ancon-toast-body',
  position: 'bottom-left',
}

const Toast = {
  success: text => {
    toast.success(text, options)
  },
  error: text => {
    toast.error(text, options)
  },
  info: text => {
    toast.info(text, options)
  },
  warn: text => {
    toast.warn(text, options)
  },
  dismiss: () => {
    toast.dismiss()
  },
}

export default Toast
