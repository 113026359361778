import { handleActions } from 'redux-actions'
import {
  DELETE_IDENTITY_USER_REQUEST,
  DELETE_IDENTITY_USER_RESPONSE,
} from '../actions/types'

const deleteIdentityUserUi = handleActions(
  {
    [DELETE_IDENTITY_USER_REQUEST]: () => ({
      isLoading: true,
    }),
    [DELETE_IDENTITY_USER_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
  },
  {},
)

const getIsSuccessful = state => state.ui.deleteIdentityUser.isSuccessful
const getIsLoading = state => state.ui.deleteIdentityUser.isLoading
const getIsError = state => state.ui.deleteIdentityUser.isError
const getError = state => state.ui.deleteIdentityUser.error

const identityDeleteUserUi = state => ({
  isSuccessful: state.ui.deleteIdentityUser.isSuccessful,
  isLoading: state.ui.deleteIdentityUser.isLoading,
  isError: state.ui.deleteIdentityUser.isError,
  error: state.ui.deleteIdentityUser.error,
})

export {
  deleteIdentityUserUi,
  identityDeleteUserUi,
  getIsSuccessful,
  getIsLoading,
  getIsError,
  getError,
}
