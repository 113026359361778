import anconAdminUsers from './anconAdminUsers'
import passportUsers from './passportUsers'
import tenantAdminUsers from './tenantAdminUsers'
import currentUser from './currentUser'
import { getUsersUi } from './getUsersUi'
import { addAnconAdminUi, anconAdminAddUi } from './addAnconAdminUi'
import { editUserUi } from './editUserUi'
import { addConnectionUi } from './addConnectionUi'
import { userTypes } from '../../../common/constants'
import tenantAdminUsersUi from './tenantAdminUsersUi'
import passportUsersUi from './passportUsersUi'
import anconAdminUsersUi from './anconAdminUsersUi'
import passportUsersDeleteUi from './passportUsersDeleteUi'
import tenantAdminUsersDeleteUi from './tenantAdminUsersDeleteUi'
import anconAdminUsersDeleteUi from './anconAdminUsersDeleteUi'
import searchUsersDeleteUi from './searchUsersDeleteUi'
import customersUi from './customersUi'
import { editIdentityUserUi } from './editIdentityUserUi'
import { deleteIdentityUserUi } from './deleteIdentityUserUi'

/* selector */
const getUserList = (state, userType) => {
  switch (userType) {
    case userTypes.PASSPORT:
      return state.passportUsers.items
    case userTypes.CUSTOMER:
      return state.customers.items
    case userTypes.ANCON_ADMIN:
      return state.anconAdminUsers.items
    case userTypes.TENANT_ADMIN:
      return state.tenantAdminUsers.items
    default:
      return []
  }
}

/* selector */
const getUserMeta = (state, userType) => {
  switch (userType) {
    case userTypes.PASSPORT:
      return state.passportUsers.meta
    case userTypes.CUSTOMER:
      return state.customers.meta
    case userTypes.ANCON_ADMIN:
      return state.anconAdminUsers.meta
    case userTypes.TENANT_ADMIN:
      return state.tenantAdminUsers.meta
    default:
      return {}
  }
}

const getUserListUi = (state, userType) => {
  switch (userType) {
    case userTypes.PASSPORT:
      return state.ui.passportUsers
    case userTypes.CUSTOMER:
      return state.ui.customers
    case userTypes.ANCON_ADMIN:
      return state.ui.anconAdminUsers
    case userTypes.TENANT_ADMIN:
      return state.ui.tenantAdminUsers
    default:
      return {}
  }
}

const getUserDeleteUi = (state, userType) => {
  switch (userType) {
    case userTypes.PASSPORT:
      return state.ui.passportUsersDelete
    case userTypes.ANCON_ADMIN:
      return state.ui.anconAdminUsersDelete
    case userTypes.TENANT_ADMIN:
      return state.ui.tenantAdminUsersDelete
    default:
      return {}
  }
}

export {
  // selectors
  getUserListUi,
  getUserDeleteUi,
  getUserList,
  getUserMeta,
  getUsersUi,
  customersUi,
  anconAdminAddUi,
  // selectors
  anconAdminUsers,
  passportUsers,
  tenantAdminUsers,
  currentUser,
  addAnconAdminUi,
  editUserUi,
  addConnectionUi,
  tenantAdminUsersUi,
  passportUsersUi,
  anconAdminUsersUi,
  passportUsersDeleteUi,
  tenantAdminUsersDeleteUi,
  anconAdminUsersDeleteUi,
  searchUsersDeleteUi,
  editIdentityUserUi,
  deleteIdentityUserUi,
}
