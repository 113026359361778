import log from 'loglevel'
import loglevelMessagePrefix from 'loglevel-message-prefix'
import remote from 'loglevel-plugin-remote'
import config from '../config'

class logger {
  constructor() {
    if (!logger.instance) {
      this._data = []
      logger.instance = this

      // Log messages bubbles up from bottom to top through below plugins.
      if (config.log && config.log.CONSOLE_LOG_LEVEL) {
        log.enableAll()
        this.initConsoleLogger()
        this.initConsoleLogFilter()
        this.initRemoteLogger()
      } else {
        log.disableAll()
      }
    }

    return logger.instance
  }

  initConsoleLogger() {
    loglevelMessagePrefix(log, {
      options: {
        timestamp: {
          hour12: false,
          locale: 'en-GB',
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      },
    })
  }

  initConsoleLogFilter() {
    const consoleLogLevel =
      (config.log && config.log.CONSOLE_LOG_LEVEL) || 'error'

    const originalFactory = log.methodFactory
    log.methodFactory = function (methodName, logLevel, loggerName) {
      const rawMethod = originalFactory(methodName, logLevel, loggerName)

      const levelVal = log.levels[methodName.toUpperCase()]
      const needLog = levelVal >= log.levels[consoleLogLevel.toUpperCase()]

      return message => {
        if (needLog) {
          rawMethod(message)
        }
      }
    }

    log.setLevel(log.getLevel())
  }

  initRemoteLogger() {
    if (config.log && config.log.REMOTE_LOG_LEVEL && config.log.REMOTE_URL) {
      const remoteLoggerOptions = {
        url: config.log.REMOTE_URL,
        level: config.log.REMOTE_LOG_LEVEL,
        timestamp: () => new Date().toISOString(),
      }
      remote.apply(log, remoteLoggerOptions)
      remote.setToken(config.log.REMOTE_API_KEY)
    }
  }

  trace(message) {
    log.trace(message)
  }

  debug(message) {
    log.debug(message)
  }

  info(message) {
    log.info(message)
  }

  warn(message) {
    log.warn(message)
  }

  error(message) {
    log.error(message)
  }
}

const instance = new logger()
Object.freeze(instance)

export default instance
