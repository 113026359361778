import React from 'react'
import PropTypes from 'prop-types'
import intl from 'react-intl-universal'
import ReactDocumentTitle from 'react-document-title'

const DocumentTitle = ({ subtitle, title, previousTitleIfEmpty, ...rest }) => {
  const mainTitle = intl.get('title.main')
  let concatTitle = mainTitle

  if (title && title.trim().length) {
    concatTitle = `${title} - ${mainTitle}`
  } else if (previousTitleIfEmpty) {
    concatTitle = document.title
  }

  if (subtitle && title.trim().length) {
    concatTitle = `${title} · ${subtitle} - ${mainTitle}`
  }

  return <ReactDocumentTitle title={concatTitle} {...rest} />
}

DocumentTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  previousTitleIfEmpty: PropTypes.bool,
}

DocumentTitle.defaultProps = {
  title: undefined,
  subtitle: undefined,
  previousTitleIfEmpty: false,
}

export default DocumentTitle
