import { handleActions } from 'redux-actions'
import {
  FILE_UPLOAD_QUEUE,
  FILE_UPLOAD_RESPONSE,
  FILE_UPLOAD_PROGRESS,
} from '../actions/types'

const getFileStatus = (state, id) => {
  if (state && state.fileUploads && state.fileUploads[id]) {
    return state.fileUploads[id]
  }
  return null
}

const fileUploads = handleActions(
  {
    [FILE_UPLOAD_QUEUE]: (state, action) => ({
      ...state,
      [action.payload.id]: {},
    }),
    [FILE_UPLOAD_RESPONSE]: {
      next: (state, action) => ({
        ...state,
        [action.payload.id]: { isSuccessful: true },
      }),
      throw: (state, action) => ({
        ...state,
        [action.payload.fileId]: {
          isInProgress: false,
          isSuccessful: false,
          isError: true,
          error: action.payload,
        },
      }),
    },
    [FILE_UPLOAD_PROGRESS]: (state, action) => ({
      ...state,
      [action.payload.id]: {
        ...action.payload,
        isInProgress: true,
        isSuccessful: false,
        isError:
          getFileStatus(state, action.payload.id) &&
          getFileStatus(state, action.payload.id).isError
            ? getFileStatus(state, action.payload.id).isError
            : false,
      },
    }),
  },
  {},
)

export default fileUploads
export { getFileStatus }
