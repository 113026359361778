import { handleActions } from 'redux-actions'
import { GET_TENANTS_REQUEST, GET_TENANTS_RESPONSE } from '../actions/types'

const getTenantsUi = handleActions(
  {
    [GET_TENANTS_REQUEST]: () => ({
      isLoading: true,
    }),
    [GET_TENANTS_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.message,
      }),
    },
  },
  {},
)

const getIsLoading = state => state.ui.getTenants.isLoading
const getIsSuccessful = state => state.ui.getTenants.isSuccessful
const getIsError = state => state.ui.getTenants.isError
const getError = state => state.ui.getTenants.error

const tenantsUi = state => ({
  isLoading: state.ui.getTenants.isLoading,
  isSuccessful: state.ui.getTenants.isSuccessful,
  isError: state.ui.getTenants.isError,
  error: state.ui.getTenants.error,
})

export {
  getTenantsUi,
  tenantsUi,
  getIsSuccessful,
  getIsLoading,
  getIsError,
  getError,
}
