import licenses, { getLicenseList, getLicenseMeta } from './licenses'
import currentLicense from './currentLicense'
import { editLicenseUi } from './editLicenseUi'
import { addLicenseUi } from './addLicenseUi'
import disableLicenseUi, {
  getIsDisableLoading,
  getIsDisableSuccessful,
  getIsDisableError,
  getDisableError,
} from './disableLicenseUi'
import deleteSearchLicenseUi, {
  getDeleteSearchLicenseUi,
} from './deleteSearchLicenseUi'
import searchLicense, {
  getSearchLicenseList,
  getSearchLicenseMeta,
} from './searchLicense'

import licenseUi from './licenseUi'

import deleteLicenseUi, {
  getIsDeleteLoading,
  getIsDeleteSuccessful,
  getIsDeleteError,
  getDeleteError,
  getDeleteLicenseUi,
} from './deleteLicenseUi'

import {
  getIsEnableLoading,
  getIsEnableSuccessful,
  getIsEnableError,
  getEnableError,
} from './enableLicenseUi'

export {
  licenses,
  currentLicense,
  editLicenseUi,
  addLicenseUi,
  getLicenseList,
  getIsDisableLoading,
  getIsDisableSuccessful,
  getIsDisableError,
  getDisableError,
  getLicenseMeta,
  searchLicense,
  getSearchLicenseList,
  getSearchLicenseMeta,
  getIsDeleteLoading,
  getIsDeleteSuccessful,
  getIsDeleteError,
  getDeleteError,
  getIsEnableLoading,
  getIsEnableSuccessful,
  getIsEnableError,
  getEnableError,
  getDeleteLicenseUi,
  deleteLicenseUi,
  disableLicenseUi,
  getDeleteSearchLicenseUi,
  deleteSearchLicenseUi,
}

export default licenseUi
