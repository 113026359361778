// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iGa7fJyM6S50EVl7WajpuA\\=\\={margin-right:60px}", "",{"version":3,"sources":["webpack://src/components/BackButton/BackButton.module.scss"],"names":[],"mappings":"AAAA,4BAAU,iBAAiB","sourcesContent":[".btn-back{margin-right:60px}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnBack": "iGa7fJyM6S50EVl7WajpuA=="
};
export default ___CSS_LOADER_EXPORT___;
