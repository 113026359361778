import { handleActions } from 'redux-actions'

import {
  GET_TENANT_DETAILS_REQUEST,
  GET_TENANT_DETAILS_RESPONSE,
  CLEAR_TENANT_DETAILS,
} from '../actions/types'

const currentTenant = handleActions(
  {
    [GET_TENANT_DETAILS_RESPONSE]: {
      next: (_, { payload }) => payload.data,
    },
    [CLEAR_TENANT_DETAILS]: () => ({}),
  },
  {},
)

const currentTenantUi = handleActions(
  {
    [GET_TENANT_DETAILS_REQUEST]: () => ({
      isLoading: true,
    }),
    [GET_TENANT_DETAILS_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isError: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.response,
      }),
    },
    [CLEAR_TENANT_DETAILS]: {
      next: state => {
        if (state.error) {
          if (state.error.status === 404) {
            return state
          }
          return {}
        }
        return {}
      },
    },
  },
  {},
)

const getIsLoading = state => state.ui.currentTenant.isLoading
const getTenant = state => state.currentTenant
const getIsError = state => state.ui.currentTenant.isError
const getError = state => state.ui.currentTenant.error

export { currentTenantUi, getIsLoading, getTenant, getIsError, getError }
export default currentTenant
