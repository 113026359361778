import { handleActions } from 'redux-actions'

import {
  CLEAR_LICENSE_DETAILS,
  GET_LICENSE_DETAILS_RESPONSE,
  GET_LICENSE_DETAILS_REQUEST,
} from '../actions/types'

const currentLicense = handleActions(
  {
    [GET_LICENSE_DETAILS_RESPONSE]: {
      next: (_, { payload }) => payload.data,
    },
    [CLEAR_LICENSE_DETAILS]: () => ({}),
  },
  {},
)

const currentLicenseUi = handleActions(
  {
    [GET_LICENSE_DETAILS_REQUEST]: () => ({
      isLoading: true,
    }),
    [GET_LICENSE_DETAILS_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isError: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.response,
      }),
    },
    [CLEAR_LICENSE_DETAILS]: {
      next: state => {
        if (state.error) {
          if (state.error.status === 404) {
            return state
          }
          return {}
        }
        return {}
      },
    },
  },
  {},
)

const getIsLoading = state => state.ui.currentLicense.isLoading
const getIsSuccessful = state => state.ui.currentLicense.isSuccessful
const getCurrentLicense = state => state.currentLicense
const getIsError = state => state.ui.currentLicense.isError
const getError = state => state.ui.currentLicense.error

export {
  currentLicenseUi,
  getIsLoading,
  getIsSuccessful,
  getCurrentLicense,
  getIsError,
  getError,
}
export default currentLicense
