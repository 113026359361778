import React from 'react'
import PropTypes from 'prop-types'

const ButtonSecondary = ({ type, disabled, onClick, children }) => (
  <button
    tabIndex={-1}
    type={type}
    className="btn btn-secondary"
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </button>
)

ButtonSecondary.propTypes = {
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

ButtonSecondary.defaultProps = {
  disabled: false,
  type: 'button',
}

export default ButtonSecondary
