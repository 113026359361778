import userManager from 'Common/userManager'
import api from 'Common/api'
import { endpoint, getUrl } from 'Common/constants'
import { getIdentityUserResponse } from 'Modules/login/actions'
import store from 'Store'

const loadIdentityUser = () =>
  new Promise((resolve, reject) => {
    userManager
      .getUser()
      .then(user => {
        if (user && user.profile && user.profile.sub) {
          const url = getUrl(endpoint.IDENTITY_URL, {
            path: { identityUserId: user.profile.sub },
          })
          return api
            .get(url)
            .then(response => {
              const payload = {
                data: response.data,
              }
              store.dispatch(getIdentityUserResponse(payload))
              resolve()
            })
            .catch(() => {
              reject()
            })
        }
      })
      .catch(() => {
        reject()
      })
  })

const hydrate = () => loadIdentityUser()

export default hydrate
