import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { ENVIRONMENT } from '../common/constants'
import config from '../config'

const enabledReleaseStages = [
  ENVIRONMENT.qa1,
  ENVIRONMENT.qa2,
  ENVIRONMENT.qa3,
  ENVIRONMENT.alpha,
  ENVIRONMENT.beta,
  ENVIRONMENT.maintenance,
  ENVIRONMENT.production,
]

const bugsnagClient = Bugsnag.start({
  apiKey: config.api.BUGSNAG_API_KEY,
  appVersion: config.APP_VERSION,
  releaseStage: config.ENVIRONMENT,
  enabledReleaseStages,
  plugins: [new BugsnagPluginReact(React)],
})

export default bugsnagClient
