import { handleActions } from 'redux-actions'
import {
  DELETE_OUTLET_CONNECTION_RESPONSE,
  GET_USER_CONNECTIONS_OUTLETS_RESPONSE,
} from '../actions/types'

const addDisabled = (outletArr, outletId) =>
  outletArr.map(outlet =>
    outlet.outletId === outletId ? { ...outlet, isEnabled: false } : outlet,
  )

const outletConnections = handleActions(
  {
    [GET_USER_CONNECTIONS_OUTLETS_RESPONSE]: {
      next: (state, action) => ({
        ...state,
        [action.payload.tenantId]: {
          outlets: action.payload.outletConnections,
        },
      }),
    },
    [DELETE_OUTLET_CONNECTION_RESPONSE]: {
      next: (state, action) => ({
        ...state,
        [action.payload.tenantId]: addDisabled(
          state[action.payload.tenantId],
          action.payload.outletId,
        ),
      }),
    },
  },
  {},
)

export const getOutletConnections = state => state.outletConnections

export default outletConnections
