import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import intl from 'react-intl-universal'
import { Field, getIn } from 'formik'

function Dropdown({
  placeholder,
  value,
  onChange,
  onBlur,
  name,
  label,
  isDisabled,
  errorLabel,
  required,
  options,
  useFormikLabel,
}) {
  function handleChange(v) {
    onChange(name, v)
  }

  function handleBlur() {
    onBlur(name, true)
  }

  return (
    <div className="form-group">
      {useFormikLabel ? (
        <ErrorMessage
          name={name}
          label={required ? `${label} *` : label}
          errorLbl={errorLabel}
        />
      ) : (
        <label htmlFor={name} className="control-label">
          {label}
        </label>
      )}

      <Select
        tabIndex={0}
        clearable={false}
        isDisabled={isDisabled}
        placeholder={placeholder || intl.get('select')}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        options={options}
        maxMenuHeight={200}
        className="ancon-react-select"
      />
    </div>
  )
}
const ErrorMessage = ({ name, label, errorLbl = '' }) => (
  <Field
    name={name}
    render={({ form }) => {
      const error = getIn(form.errors, name)
      const touch = getIn(form.touched, name)
      const errorLabel = errorLbl === '' ? label : errorLbl

      const errorMessage = `${errorLabel.replace('*', '').toUpperCase()}: ${
        error && error.label
      }`
      return touch && error ? (
        <label htmlFor={name} className={`${'control-label'} ${'error-label'}`}>
          {errorMessage}
        </label>
      ) : (
        <label htmlFor={name} className="control-label">
          {label}
        </label>
      )
    }}
  />
)
Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  isDisabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  errorLabel: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  useFormikLabel: PropTypes.bool,
}

Dropdown.defaultProps = {
  label: '',
  value: {},
  onBlur: () => {},
  isDisabled: false,
  errorLabel: '',
  required: false,
  placeholder: undefined,
  useFormikLabel: true,
}

ErrorMessage.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  errorLbl: PropTypes.string,
}
ErrorMessage.defaultProps = {
  errorLbl: '',
}

export default Dropdown
