import { AdminUserRole, CountryCode } from '@ancon/wildcat-types'
import qs from 'qs'

export const ENVIRONMENT = Object.freeze({
  development: 'development',
  staging1: 'staging1',
  staging2: 'staging2',
  staging3: 'staging3',
  qa1: 'qa1',
  qa2: 'qa2',
  qa3: 'qa3',
  alpha: 'alpha',
  beta: 'beta',
  maintenance: 'maintenance',
  production: 'production',
})

export const DEFAULT_RESULTS_LIMIT = 10
export const DEFAULT_PAGE_NUMBER = 1
export const DEFAULT_RESULTS_OFFSET = 0
export const DEFAULT_ORDER_BY = 'created'
export const DEFAULT_ORDER_DIRECTION = 'desc'
export const DEFAULT_ORDER_BY_USERS = 'Created'

export const PaginationActions = Object.freeze({
  toFirst: 'TO FIRST',
  backward: 'BACKWARD',
  forward: 'FORWARD',
  toEnd: 'TO END',
})

export const PageStatus = Object.freeze({
  first: 'FIRST',
  last: 'LAST',
  middle: 'MIDDLE',
  only: 'ONLY',
})

export const invitationStatus = Object.freeze({
  ACTIVE: 'active',
  PENDING: 'pending',
})

export const userTypes = Object.freeze({
  PASSPORT: 'passport-users',
  ANCON_ADMIN: 'ancon-admins',
  TENANT_ADMIN: 'tenant-admins',
  CUSTOMER: 'customers',
})

export const actionTypes = Object.freeze({
  DELETE: 'delete',
  DISABLE: 'disable',
  UNDELETE: 'undelete',
  EDIT: 'edit',
  VIEW: 'view',
  ENABLE: 'enable',
  CREATE: 'create',
})

export const connectionTypes = Object.freeze({
  TENANTS: 'tenants',
  OUTLETS: 'outlets',
})

export const EntityTypes = Object.freeze({
  IDENTITY_USER: 'identityUser',
  BROADCAST_MESSAGE: 'broadcastMessage',
})

export const ANCON_PRIMARY_COLOR = '#1d3d91'
export const ANCON_SECONDARY_COLOR = '#f95919'
export const WHITE_COLOR = '#f7f7fa'

export const colorBlockType = Object.freeze({
  small: 'colorBlockSmall',
  large: 'colorBlockLarge',
})

export const OPERATIONS = Object.freeze({
  LIST: 'list',
  SEARCH: 'search',
  CURRENT: 'current',
  EDIT: 'edit',
  CREATE: 'create',
  DELETE: 'delete',
  DISABLE: 'disable',
})

export const navTypes = Object.freeze({
  outlet: 'outlet',
  tenant: 'tenant',
})
export const resultsPerPageList = [10, 25, 50, 100]

export const TOKEN_KEY = 'token'

export const endpoint = Object.freeze({
  PACKAGES: '/packages',
  PACKAGES_USAGE: '/packages/usage',
  MEDIA: '/Media',
  IDENTITY_URL: '/IdentityUsers/{identityUserId}',
  CURRENCIES: '/Currencies/CurrencyCodes',
  UPLOAD_GIFT_CARD_LIST: '/Tenants/{tenantId}/GiftCards/UploadCSV',
  UPLOAD_WALLET_LIST: '/Tenants/{tenantId}/PrepaidWallets/UploadCSV',
})

export const shareableTypes = Object.freeze({
  none: 0,
  products: 1,
  ingredients: 2,
  addOnGroups: 4,
  menuTrees: 8,
})

export const summedShareableTypes = Object.freeze({
  none: 0,
  products: 7,
  all: 15,
})

export const getUrl = (
  base,
  params = {},
  version = 1,
  qsConfig = { arrayFormat: 'repeat' },
) => {
  let url = `v${version.toFixed(1)}${base}`
  const { path, query } = params
  // eslint-disable-next-line no-prototype-builtins
  if (params.hasOwnProperty('path') && path !== undefined) {
    Object.keys(path).forEach(key => {
      url = url.replace(`{${key}}`, path[key])
    })
  }
  // eslint-disable-next-line no-prototype-builtins
  if (params.hasOwnProperty('query') && query !== undefined) {
    const queryParam = {}
    Object.keys(query).forEach(param => {
      queryParam[param] = query[param]
    })
    url = `${url}?${qs.stringify(queryParam, qsConfig)}`
  }

  return url
}

export const OUTLET_ERROR_CODES = {
  URL_SLUG_TAKEN: 'fbe7950d-a4e9-45d2-bc30-6ea030588328',
}

export const ERRORS = {
  noClientsFound: 'c46d7f41-2ed3-4d73-8cb9-ad9ba2a68be0',
}

export const betaTenantTypes = Object.freeze({
  Test: 0,
  Live: 1,
})

export const languageCodes = Object.freeze({
  sv: 'sv-SE',
  en: 'en-US',
  nb: 'nb-NO',
})

export const DEFAULT_COUNTRY_CODE = CountryCode.Sweden

export const DEFAULT_CURRENCY_CODE = 'SEK'

export const smsProviderTypes = Object.freeze({
  twilio: 0,
  generic: 1,
})

export const AdminUserRoleOptions = [
  { value: AdminUserRole.Viewer, label: 'Viewer' },
  { value: AdminUserRole.Editor, label: 'Editor' },
  { value: AdminUserRole.SuperAdmin, label: 'Super Admin' },
]
