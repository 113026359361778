// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./spinner.gif";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._4EJnsxft8KUWaw9Y0Yz5zg\\=\\={position:absolute;background:#fff url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center center;top:0;left:0;width:100%;height:100%}", "",{"version":3,"sources":["webpack://src/components/Spinner/Spinner.module.scss"],"names":[],"mappings":"AAAA,6BAAS,iBAAiB,CAAC,+EAA4D,CAAC,KAAK,CAAC,MAAM,CAAC,UAAU,CAAC,WAAW","sourcesContent":[".spinner{position:absolute;background:#fff url(\"./spinner.gif\") no-repeat center center;top:0;left:0;width:100%;height:100%}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": "_4EJnsxft8KUWaw9Y0Yz5zg=="
};
export default ___CSS_LOADER_EXPORT___;
