import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import style from './BackButton.module.scss'

const BackButton = ({ history }) => {
  const url = history.location.pathname.split('/')[1]

  return (
    <button
      tabIndex={-1}
      type="button"
      className={`${style.btnBack} ${'btn btn-secondary btn-icon'}`}
      onClick={() => history.push(`/${url}`)}
    >
      <i className="a_icon-arrow_back" />
    </button>
  )
}

BackButton.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
}

export default withRouter(BackButton)
