import { handleActions } from 'redux-actions'
import {
  GET_IDENTITY_USER_BY_ID_REQUEST,
  CLEAR_CURRENT_USER_UI,
  GET_IDENTITY_USER_BY_ID_RESPONSE,
  CLEAR_CURRENT_USER,
} from '../actions/types'

const currentUser = handleActions(
  {
    [GET_IDENTITY_USER_BY_ID_RESPONSE]: {
      next: (_, { payload }) => payload.data,
    },
    [CLEAR_CURRENT_USER]: {
      next: () => ({}),
    },
  },
  {},
)

const currentUserUi = handleActions(
  {
    [GET_IDENTITY_USER_BY_ID_REQUEST]: () => ({
      isLoading: true,
    }),
    [GET_IDENTITY_USER_BY_ID_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isError: false,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload.response,
      }),
    },
    [CLEAR_CURRENT_USER_UI]: {
      next: state => {
        if (state.error) {
          if (state.error.status === 404) {
            return state
          }
          return {}
        }
        return {}
      },
    },
  },
  {},
)

const getIsLoading = state => state.ui.currentUser.isLoading
const getTenant = state => state.currentUser
const getIsError = state => state.ui.currentUser.isError
const getError = state => state.ui.currentUser.error
const getCurrentUser = state => state.currentUser

export {
  currentUserUi,
  getIsLoading,
  getTenant,
  getIsError,
  getError,
  getCurrentUser,
}
export default currentUser
