import { handleActions } from 'redux-actions'
import {
  ADD_ANCON_ADMIN_USER_REQUEST,
  ADD_ANCON_ADMIN_USER_RESPONSE,
} from '../actions/types'

const addAnconAdminUi = handleActions(
  {
    [ADD_ANCON_ADMIN_USER_REQUEST]: () => ({
      isLoading: true,
    }),
    [ADD_ANCON_ADMIN_USER_RESPONSE]: {
      next: () => ({
        isLoading: false,
        isSuccessful: true,
      }),
      throw: (_, action) => ({
        isLoading: false,
        isError: action.error,
        error: action.payload,
      }),
    },
  },
  {},
)

const getIsLoading = state => state.ui.addAnconAdmin.isLoading
const getIsSuccessful = state => state.ui.addAnconAdmin.isSuccessful
const getIsError = state => state.ui.addAnconAdmin.isError
const getError = state => state.ui.addAnconAdmin.error

const anconAdminAddUi = state => ({
  isLoading: state.ui.addAnconAdmin.isLoading,
  isSuccessful: state.ui.addAnconAdmin.isSuccessful,
  isError: state.ui.addAnconAdmin.isError,
  error: state.ui.addAnconAdmin.error,
})

export {
  addAnconAdminUi,
  getIsSuccessful,
  anconAdminAddUi,
  getIsLoading,
  getIsError,
  getError,
}
